import React, { useRef, useState } from 'react';
import {
  Form,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import {
  InputElem,
} from '../../common/form-elements';
import Notifications from '../../common/notifications';
import { payServiceBill } from '../../../api/service-bill';
import Enums from '../../../constants/enum';

const Payment = ({
  form,
  bPayment,
  record,
  closeInvoicing,
  applyFilter,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const InvoiceFormRef = useRef(null);

  const save = async () => {
    setIsSubmitting(true);
    const formFieldValues = form.getFieldsValue();
    const { paidAmount } = formFieldValues;
    const { id } = record.record;
    try {
      const prepObj = {
        paidAmount,
      };
      await payServiceBill(id, prepObj);
      Notifications.success('Successfully record payment!');
      setIsSubmitting(false);
      closeInvoicing();
      applyFilter({});
    } catch (error) {
      setIsSubmitting(false);
      Notifications.error('Something went wrong while recording payment!');
    }
  };

  const close = () => {
    closeInvoicing();
  };

  const invoiceSave = async (e) => {
    e.preventDefault();
    form.validateFields((err) => {
      if (!err) {
        save();
      }
    });
  };

  const getPayableAmount = () => {
    if (!record) {
      return 0;
    }
    const dueInvoice = (parseFloat(record.record.dueAmount) > 0);
    if (dueInvoice) {
      return parseFloat(record.record.dueAmount).toFixed(Enums.Formats.AmountPrecision);
    }
    return parseFloat(record.record.invoiceAmount).toFixed(Enums.Formats.AmountPrecision);
  };

  return (
    <div>
      <Modal
        title="Invoice"
        visible={bPayment}
        okButtonProps={{ form: 'invoicing-form', key: 'submit', htmlType: 'submit' }}
        onCancel={close}
        confirmLoading={isSubmitting}
      >
        <Form onSubmit={invoiceSave} ref={InvoiceFormRef} id="invoicing-form">
          <InputElem
            placeholder="Invoice Amount"
            fieldName="invoiceAmount"
            form={form}
            initialValue={record && record.record.totalAmount}
            disabled
            number
          />
          <InputElem
            placeholder="Payment Amount"
            fieldName="paidAmount"
            form={form}
            initialValue={getPayableAmount()}
            type="number"
            rules={[
              { required: true, message: 'Please enter an payment amount.' },
            ]}
            number
          />
        </Form>
      </Modal>
    </div>
  );
};

Payment.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bPayment: PropTypes.bool.isRequired,
  record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  closeInvoicing: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
};

const PaymentWrapper = Form.create({ name: 'payment-form' })(Payment);

export default PaymentWrapper;
