import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import PropTypes from 'prop-types';
import AuthService from '../../containers/auth';
import Header from './header';
import Sidebar from './sidebar';
import * as Storage from '../../util/storage';
import RouteRegistry from '../../router/registry';
import './index.css';

const Layout = ({ body }) => {
  const history = useHistory();
  const authService = AuthService.useContainer();
  const [activeCurrency, setActiveCurrency] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(RouteRegistry.login.path);
  const [isSwitichingCurrency, setIsSwitichingCurrency] = useState(false);
  const [Body, setBody] = useState(body());

  const setCurrency = (newCurrency) => {
    new Promise((resolve) => {
      setIsSwitichingCurrency(true);
      setActiveCurrency(false);
      setBody(null);
      Storage.set(process.env.REACT_APP_CURRENCY_NAME, newCurrency);
      resolve();
    }).then(
      () => {
        setActiveCurrency(newCurrency);
        setBody(body());
        setIsSwitichingCurrency(false);
      },
    );
  };

  useEffect(() => {
    setActiveCurrency(Storage.get(process.env.REACT_APP_CURRENCY_NAME));
    setCurrentLocation(history.location.pathname);
  }, [history.location]);

  const switichingCurrency = isSwitichingCurrency
    ? <Spin size="large" tip="Switching Currency..."> </Spin>
    : (
      <Alert
        message="Currency Not Selected"
        description="You need to select a currency to load the data from the sever."
        type="info"
        showIcon
      />
    );

  const conditionalRendaringOnCurrencyAvailability = activeCurrency
    ? Body
    : switichingCurrency;

  return (
    <div className="layout">
      <div className="sider">
        <Sidebar />
      </div>
      <div className="custom-content">
        <div className="header">
          <Header
            setCurrency={setCurrency}
            currency={activeCurrency}
            authService={authService}
          />
        </div>
        <div className="content-body">
          {
            (currentLocation === RouteRegistry.login.path)
            || (currentLocation === RouteRegistry.forgotPassword.path)
              ? Body
              : conditionalRendaringOnCurrencyAvailability
          }
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  body: PropTypes.func.isRequired,
};

export default Layout;
