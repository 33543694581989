import React, { useState, useEffect } from 'react';
import {
  Divider,
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import {
  SelectBox,
} from '../../common/form-elements';
import Notifications from '../../common/notifications';
import { getClientsForFilter } from '../../../api/client';

const FilterForm = ({
  form,
  filterObj,
  applyFilter,
  setFilterObj,
  statuses,
}) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const gettingClients = async () => {
      try {
        const res = await getClientsForFilter();
        const settingClients = res.data.map((client) => {
          return {
            text: client.code,
            key: client.code,
          };
        });
        setClients(settingClients);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load clients!');
      }
    };
    gettingClients();
  }, []);

  const filterSubmit = async (e) => {
    e.preventDefault();
    const filterValues = form.getFieldsValue();
    const tempFilterObj = { ...filterObj };
    tempFilterObj.clientCode = filterValues.clientCode;
    tempFilterObj.status = filterValues.quationStatus;
    applyFilter(tempFilterObj);
  };

  const filterClear = () => {
    const obj = {
      clientCode: '',
      status: '',
    };
    const tempFilterObj = { ...filterObj, ...obj };
    setFilterObj(tempFilterObj);
    applyFilter(tempFilterObj);
    form.resetFields();
  };

  return (
    <div className="filter-level-one-holder">
      <Divider />
      <Form onSubmit={filterSubmit}>
        <div className="filter-form-content">
          <div className="filter-item">
            <p>Client</p>
            <div className="filter-item-control">
              <SelectBox
                form={form}
                fieldName="clientCode"
                options={clients}
              />
            </div>
          </div>
          <div className="filter-item">
            <p>Status</p>
            <div className="filter-item-control">
              <SelectBox
                form={form}
                fieldName="quationStatus"
                options={statuses}
                textValueSplit
              />
            </div>
          </div>
          <div className="filter-button-holder">
            <Button htmlType="submit" type="primary">Filter</Button>
            <Button htmlType="button" type="dashed" onClick={filterClear}>Clear Filter</Button>
          </div>
        </div>
      </Form>
      <Divider />
    </div>
  );
};

FilterForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  applyFilter: PropTypes.PropTypes.func.isRequired,
  setFilterObj: PropTypes.PropTypes.func.isRequired,
  filterObj: PropTypes.oneOfType([PropTypes.object]),
  statuses: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

FilterForm.defaultProps = {
  filterObj: {},
};

const FilterFormWrapper = Form.create({ name: 'quotation-filter-form' })(FilterForm);

export default FilterFormWrapper;
