import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon as AntIcon,
  Tooltip,
} from 'antd';

import './index.css';

const Icon = ({ toolTip, icon, onClick }) => {
  return (
    <Tooltip title={toolTip}>
      <AntIcon type={icon} className="grid-action-icon" onClick={onClick} />
    </Tooltip>
  );
};

Icon.propTypes = {
  toolTip: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  toolTip: '',
  onClick: () => {},
};

export default Icon;
