import React, { useState } from 'react';
import {
  List,
  Modal,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import Enums from '../../../constants/enum';

const LoadOpenInvoiceList = ({
  open,
  records,
  close,
  selectedInvoiceCallback,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState([]);

  const closeModal = () => {
    close();
  };

  const selectInvoice = (e, invoice) => {
    setSelectedInvoice(invoice);
  };

  const ok = () => {
    selectedInvoiceCallback(selectedInvoice);
    close();
  };

  const selectedColor = (item) => {
    return selectedInvoice.id === item.id ? '#F3F3F3' : 'transparent';
  };

  const getTag = (record) => {
    const { s_code: status, id } = record;
    let tagColor = '';
    switch (status) {
      case Enums.InvoiceStatus.PENDING:
        tagColor = 'orange'; break;
      case Enums.InvoiceStatus.DUE:
        tagColor = 'red'; break;
      default:
        break;
    }
    return (
      <Tag color={tagColor} key={id}>
        {status.replace(/([A-Z])/g, ' $1')}
      </Tag>
    );
  };

  return (
    <div>
      <Modal
        title="Invoices"
        visible={open}
        onCancel={closeModal}
        onOk={ok}
      >
        <List
          size="small"
          bordered
          dataSource={records}
          renderItem={
            (item) => (
              <List.Item
                style={{ backgroundColor: selectedColor(item) }}
                onClick={(e) => selectInvoice(e, item)}
              >
                {getTag(item)}
                <br />
                {`${item.q_code} ${item.title} 
                Invoice Amount: Rs. ${item.s_code === Enums.InvoiceStatus.DUE ? item.dueAmount : item.invoiceAmount}`}
              </List.Item>
            )
          }
        />
      </Modal>
    </div>
  );
};

LoadOpenInvoiceList.propTypes = {
  open: PropTypes.bool.isRequired,
  records: PropTypes.oneOfType([PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
  selectedInvoiceCallback: PropTypes.func.isRequired,
};

export default LoadOpenInvoiceList;
