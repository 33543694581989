import React, { useState, useEffect } from 'react';
import {
  Divider,
  Input,
  InputNumber,
  Select,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import { queryInvoicables } from '../../../api/invoice';
import { getProductCategories } from '../../../api/product-category';
import { InputList } from '../../common/form-elements';
import Enums from '../../../constants/enum';
import { thousandSeparator, getSum } from '../../../util/data/converter';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import LoadOpenInvoiceList from './select-open-invoice';
import './index.css';

const { Option } = Select;
const { error } = Modal;

const InvoiceItem = ({
  invoiceItems,
  setInvoiceItems,
  invoiceItemError,
  clientCode,
}) => {
  const [openInvoiceList, setOpenInvoiceList] = useState([]);
  const [openInvoiceModel, setOpenInvoiceModel] = useState(false);
  const [itemId, setItemId] = useState('');
  const [productCategories, setProductCategories] = useState([]);

  const getInvoicesList = async () => {
    const res = await queryInvoicables({
      currency: getCurrencyFromLocalStorage(),
      clientCode,
      status: [
        Enums.InvoiceStatus.PENDING,
        Enums.InvoiceStatus.DUE,
      ],
    });
    const gotInvoices = res.data;
    setOpenInvoiceList(gotInvoices);
  };

  useEffect(() => {
    const getProductCategoryList = async () => {
      const res = await getProductCategories({});
      const gotProductCategories = res.data;
      setProductCategories(gotProductCategories);
    };

    getInvoicesList();
    getProductCategoryList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoicesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode]);

  const addInvoiceItem = (key) => {
    const items = [...invoiceItems];
    const newItem = {
      key,
      productCategory: '',
      description: '',
      quantity: 0,
      amount: 0,
      invoiceId: '',
    };
    items.push(newItem);
    setInvoiceItems(items);
  };

  const handleChange = (event, identifier) => {
    const { name, value } = event.target;
    const pPlans = [...invoiceItems];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], [name]: value };
    setInvoiceItems(pPlans);
  };

  const handleNumberChange = (value, name, identifier) => {
    const pPlans = [...invoiceItems];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], [name]: value };
    setInvoiceItems(pPlans);
  };

  function handleSelect(value, identifier) {
    const name = 'productCategory';
    const pPlans = [...invoiceItems];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], [name]: value };
    setInvoiceItems(pPlans);
  }

  const removeItem = (identifier) => {
    const pPlans = [...invoiceItems];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans.splice(indexOf, 1);
    setInvoiceItems(pPlans);
  };

  const ShowTotal = () => {
    const totalValue = getSum(invoiceItems.map((item) => item.amount));

    return (
      <div className="repeatable-total">
        {thousandSeparator(totalValue)}
      </div>
    );
  };

  const ErrorStatus = () => {
    if (invoiceItemError) {
      return <p className="repeatable-form-error">{invoiceItemError}</p>;
    }
    return null;
  };

  const loadInvoicesList = (key) => {
    if (clientCode) {
      setOpenInvoiceModel(true);
      setItemId(key);
    } else {
      error({
        content: 'Please select client first',
        okType: 'danger',
      });
    }
  };

  const closeInvoiceModel = () => {
    setOpenInvoiceModel(false);
  };

  const handleModalSelect = (invoice) => {
    const {
      invoiceAmount,
      dueAmount,
      q_code: qCode,
      s_code: sCode,
      id,
    } = invoice;
    const qCodeKey = 'description';
    const amountKey = 'amount';
    const quantityKey = 'quantity';
    const invoiceIdKey = 'invoiceId';
    const amount = parseInt(invoiceAmount, 10);
    const pPlans = [...invoiceItems];
    const indexOf = findIndex(pPlans, (i) => i.key === itemId);
    pPlans[indexOf] = { ...pPlans[indexOf], [qCodeKey]: qCode };
    pPlans[indexOf] = {
      ...pPlans[indexOf], [amountKey]: sCode === Enums.InvoiceStatus.DUE ? dueAmount : amount,
    };
    pPlans[indexOf] = { ...pPlans[indexOf], [quantityKey]: 1 };
    pPlans[indexOf] = { ...pPlans[indexOf], [invoiceIdKey]: id };
    setInvoiceItems(pPlans);
  };

  const LoadOpenInvoices = () => {
    if (!openInvoiceModel) {
      return null;
    }
    return (
      <LoadOpenInvoiceList
        open={openInvoiceModel}
        records={openInvoiceList}
        close={closeInvoiceModel}
        selectedInvoiceCallback={(invoice) => handleModalSelect(invoice)}
      />
    );
  };

  return (
    <div className="repeatable-form-container">
      <Divider />
      <InputList
        items={
          invoiceItems.map((item) => ({
            key: item.key,
            body: (
              <>
                <Select
                  style={{ width: 280 }}
                  onChange={(value) => handleSelect(value, item.key)}
                  placeholder={item.productCategory}
                >
                  {
                    productCategories.map((categoryItem) => {
                      return (
                        <Option
                          value={categoryItem.name}
                        >
                          {categoryItem.name}
                        </Option>
                      );
                    })
                  }
                </Select>
                <Input
                  type="text"
                  name="description"
                  value={item.description}
                  placeholder="Description"
                  onChange={(e) => { handleChange(e, item.key); }}
                  className="pp-description"
                  onClick={() => loadInvoicesList(item.key)}
                />
                <InputNumber
                  name="quantity"
                  value={1}
                  placeholder="Quantity"
                  disabled
                  onChange={(value) => { handleNumberChange(value, 'quantity', item.key); }}
                  className="ii-quantity"
                />
                <InputNumber
                  precision={Enums.Formats.AmountPrecision}
                  name="amount"
                  value={item.amount}
                  placeholder={`Price (${getCurrencyFromLocalStorage()})`}
                  onChange={(value) => { handleNumberChange(value, 'amount', item.key); }}
                  className="ii-amount"
                />
                <InputNumber
                  precision={Enums.Formats.AmountPrecision}
                  name="total"
                  value={1 * item.amount === undefined ? 0 : item.amount}
                  placeholder={`Amount (${getCurrencyFromLocalStorage()})`}
                  className="ii-total"
                />
              </>
            ),
          }))
        }
        addItem={addInvoiceItem}
        removeItem={removeItem}
      />
      {LoadOpenInvoices()}
      <ErrorStatus />
      <ShowTotal />
    </div>
  );
};

InvoiceItem.propTypes = {
  invoiceItems: PropTypes.func.isRequired,
  setInvoiceItems: PropTypes.func.isRequired,
  invoiceItemError: PropTypes.string.isRequired,
  clientCode: PropTypes.string.isRequired,
};

export default InvoiceItem;
