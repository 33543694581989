import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const getInvoices = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`/invoices?${queryString}`);
  return response;
};

const getInvoiceStatuses = async () => {
  const response = await axios.get('/statuses/invoice');
  return response;
};

const queryInvoices = async (filterOptions) => {
  const response = await axios.post('/invoices/query', filterOptions);
  return response;
};

const dashboardInvoices = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`/invoices/dashboard/upcoming-invoices?${queryString}`);
  return response;
};

const invoice = async (invoicingData) => {
  const response = await axios.put('/invoices/invoice', invoicingData);
  return response;
};

const newinvoice = async (invoiceData) => {
  const response = await axios.post('/quotations/new/invoice', invoiceData);
  return response;
};

const queryInvoicables = async (filterOptions) => {
  const response = await axios.post('/invoices/service-bill/item', filterOptions);
  return response;
};

export {
  getInvoices,
  getInvoiceStatuses,
  queryInvoices,
  dashboardInvoices,
  invoice,
  newinvoice,
  queryInvoicables,
};
