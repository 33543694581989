import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  InputElem,
  SelectBox,
} from '../../common/form-elements';
import FinancialSet from './financial';
import PaymentPlanSet from './payments';
import ReleasePlanSet from './release-plan';
import Notifcations from '../../common/notifications';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Enums from '../../../constants/enum';
import { createQuotation, getQuotation, updateQuotation } from '../../../api/quotation';
import { getClientsForFilter } from '../../../api/client';
import { getSum } from '../../../util/data/converter';
import { getQueryString, getCurrencyFromLocalStorage } from '../../../util/common-util';
import 'antd/dist/antd.css';
import './index.css';

const QuotationForm = ({ form, match }) => {
  const history = useHistory();
  const location = useLocation();

  const [isSubmitting, setSubmitting] = useState(false);
  const [clients, setClients] = useState([]);
  const [showModificationWarning, setShowModificationWarning] = useState(true);
  const [financialItems, SetFinancialItems] = useState([{
    key: 'fItem-0',
    description: '',
    amount: '',
  }]);
  const [paymentPlans, SetPaymentPlans] = useState([{
    key: 'pp-0',
    description: '',
    date: moment().format(Enums.Formats.DateFormat),
    amount: '',
  }]);
  const [releasePlans, SetReleasePlans] = useState([{
    key: 'rel-0',
    description: '',
    releaseDate: moment().format(Enums.Formats.DateFormat),
  }]);
  const [financialError, setFinancialError] = useState('');
  const [paymentError, setPaymentError] = useState(null);
  const [releasePlanError, setReleasePlanError] = useState(null);
  const [editingQuotation, setEditingQuotation] = useState(null);

  useEffect(() => {
    const prepareClients = async () => {
      const response = await getClientsForFilter();
      const gotClients = response.data;
      const updatedClients = gotClients.map((client) => {
        return {
          text: client.code,
          key: client.code,
        };
      });
      setClients(updatedClients);
    };
    prepareClients();
    // eslint-disable-next-line
  }, []);

  const getRandomKey = (prefix) => {
    const timestamp = moment().format('x');
    return `${prefix}-${timestamp}`;
  };

  const settingExistingData = (data, isHardCopy = true) => {
    const formData = {
      clientCode: data.client.code,
      title: data.title,
      scope: data.scope,
      terms: data.terms,
    };

    if (isHardCopy) {
      formData.id = data.id;
      formData.code = data.code;
      formData.status = data.status;
    }

    const prepInvoices = data.invoices.map((invoice) => {
      const { id } = invoice;
      const copiedInvoice = {
        key: invoice.id,
        description: invoice.description,
        amount: parseFloat(invoice.invoiceAmount),
        date: moment(invoice.invoiceDate).format(Enums.Formats.DateFormat),
      };

      if (isHardCopy) {
        copiedInvoice.id = id;
      }

      return copiedInvoice;
    });
    SetPaymentPlans(prepInvoices);

    const financialItemsWithKeys = data.financials.map((finItem) => {
      const { id, amount, ...rest } = finItem;
      const copiedFinancialItem = {
        ...rest,
        key: id,
        amount: parseFloat(amount),
      };

      if (isHardCopy) {
        copiedFinancialItem.id = id;
      }

      return copiedFinancialItem;
    });
    SetFinancialItems(financialItemsWithKeys);

    const plansWithKeys = data.releasePlans.map((relPlan) => {
      const { id, ...rest } = relPlan;
      const copiedReleasePlan = {
        ...rest,
        key: id,
      };

      if (isHardCopy) {
        copiedReleasePlan.id = id;
      }

      return copiedReleasePlan;
    });
    if (plansWithKeys.length === 0) {
      SetReleasePlans([{
        key: 'rel-0',
        description: '',
        releaseDate: moment().format(Enums.Formats.DateFormat),
      }]);
    } else {
      SetReleasePlans(plansWithKeys);
    }

    setEditingQuotation(formData);
  };

  useEffect(() => {
    const gettingQuotation = async () => {
      const copyId = getQueryString(location.search, 'copyId');

      if (match.params.id) {
        const res = await getQuotation(match.params.id);
        settingExistingData(res.data);
      } else if (copyId) {
        const res = await getQuotation(copyId);
        settingExistingData(res.data, false);
      }
    };
    gettingQuotation();
    // eslint-disable-next-line
  }, [match]);

  const financialsInvalid = () => {
    const errorList = [];
    let errorMessage = '';
    financialItems.forEach((item) => {
      if (item.description.trim() === '') {
        errorList.push(item);
        errorMessage = 'Invalid financial description(s) found, Check entered financial details.';
      }
    });
    let valid = true;
    if (errorList.length > 0) {
      valid = false;
    }
    return {
      valid,
      errorMessage,
    };
  };

  const paymentsValid = () => {
    const errorList = [];
    let errorMessage = '';
    paymentPlans.forEach((item) => {
      if (item.description.trim() === '' && item.amount <= 0 && !item.date) {
        errorList.push(item);
        errorMessage = 'Invalid payment description(s), date(s) and amount(s) found, ';
      } else if (item.description.trim() === '') {
        errorList.push(item);
        errorMessage = 'Invalid payment description(s) found, ';
      } else if (!item.date) {
        errorList.push(item);
        errorMessage = 'Invalid payment date(s) found, ';
      } else if (item.amount <= 0) {
        errorList.push(item);
        errorMessage = 'Invalid payment amount(s) found, ';
      }
    });
    let isErrorInDates = false;
    paymentPlans.forEach((paymentItem, index) => {
      if (index > 0) {
        const dateStampOfPrev = moment(paymentPlans[index - 1].date).format('x');
        const dateStampOfCurrent = moment(paymentItem.date).format('x');
        if (dateStampOfCurrent < dateStampOfPrev) {
          isErrorInDates = true;
        }
      }
    });

    let valid = true;
    if (errorList.length > 0) {
      valid = false;
    }
    if (isErrorInDates) {
      errorMessage += 'Each payment date should be ahead of the previous payment date.';
      valid = false;
    }
    errorMessage = `${errorMessage} Check entered payment details.`;
    return {
      valid,
      errorMessage,
    };
  };

  const releasePlansValid = () => {
    const errorList = [];
    let errorMessage = '';
    let nulledReleasePlanCount = 0;
    releasePlans.forEach((item) => {
      if (item.description.trim() === '' && !item.releaseDate) {
        errorList.push(item);
        errorMessage = 'Invalid release plan description(s) and date(s) found, ';
      } else if (item.description.trim() === '') {
        errorList.push(item);
        errorMessage = 'Invalid release plan description(s) found, ';
        nulledReleasePlanCount += 1;
      } else if (!item.releaseDate) {
        errorList.push(item);
        errorMessage = 'Invalid release plan date(s) found, ';
      }
    });
    let isErrorInDates = false;
    releasePlans.forEach((releaseItem, index) => {
      if (index > 0) {
        const dateStampOfPrev = moment(releasePlans[index - 1].releaseDate).format('x');
        const dateStampOfCurrent = moment(releaseItem.releaseDate).format('x');
        if (dateStampOfCurrent < dateStampOfPrev) {
          isErrorInDates = true;
        }
      }
    });
    let valid = true;
    if (errorList.length > 0 && nulledReleasePlanCount !== releasePlans.length) {
      valid = false;
    }
    if (isErrorInDates) {
      errorMessage += 'Each release date should be ahead of the previous release date.';
      valid = false;
    }
    errorMessage = `${errorMessage} Check entered release plan details.`;
    return {
      doDiscard: (nulledReleasePlanCount === releasePlans.length),
      valid,
      errorMessage,
    };
  };

  const isPaymentTotalValid = () => {
    const financialTotal = getSum(financialItems.map((item) => item.amount));
    const paymentTotal = getSum(paymentPlans.map((item) => item.amount));

    return paymentTotal === financialTotal;
  };

  const saveQuotation = async (values) => {
    try {
      setFinancialError(null);
      setPaymentError(null);
      setReleasePlanError(null);

      const financialValidationObj = financialsInvalid();
      if (!financialValidationObj.valid) {
        setFinancialError(financialValidationObj.errorMessage);
        return;
      }

      const releaseValidationObj = releasePlansValid();
      if (!releaseValidationObj.valid) {
        setReleasePlanError(releaseValidationObj.errorMessage);
        return;
      }

      const invoiceValidationObj = paymentsValid();
      if (!invoiceValidationObj.valid) {
        setPaymentError(invoiceValidationObj.errorMessage);
        return;
      }

      if (!isPaymentTotalValid()) {
        setPaymentError('Total on Payment plan should tally with the total on Financials.');
        return;
      }

      setSubmitting(true);

      const prepedPayments = paymentPlans.map((p) => {
        return {
          id: p.id,
          description: p.description,
          invoiceAmount: p.amount,
          invoiceDate: p.date,
        };
      });

      const rel = releaseValidationObj.doDiscard ? [] : releasePlans;

      const quotationObj = {
        title: values.pojectTitle,
        client: values.clientCode,
        scope: values.pojectScope,
        terms: values.projectTerms,
        financials: financialItems,
        invoices: prepedPayments,
        releasePlans: rel,
      };
      if (editingQuotation && editingQuotation.id) {
        await updateQuotation(editingQuotation.id, quotationObj);
        Notifcations.success('Quotation successfully updated.');
      } else {
        await createQuotation({
          ...quotationObj,
          currency: getCurrencyFromLocalStorage(),
        });
        Notifcations.success('Quotation successfully created.');
      }
      setSubmitting(false);
      goHere(history, RouteRegistry.quotation.path);
    } catch (error) {
      setSubmitting(false);
      Notifcations.error('Something went wrong when saving quotation.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveQuotation(values);
      }
    });
  };

  const formSubmitButtonText = () => {
    if (editingQuotation && editingQuotation.id) {
      return 'Update';
    }
    return 'Create';
  };

  const ModificationWarning = () => {
    if ((editingQuotation && editingQuotation.status && editingQuotation.status.code === 'Approved') && showModificationWarning) {
      return (
        <div className="locked-options-overlay">
          <div className="locked-options-overlay__container">
            <p>
              Adjusting financial and payment plan information on an already approved
              quotation will result in changes to the already released invoice on the system.
            </p>
            <p>Proceed at your own risk.</p>
            <Button type="danger" onClick={() => setShowModificationWarning(false)}>Unlock</Button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="content-container from">
      <div className="form">
        <Form onSubmit={handleSubmit} className="quotations-form">
          <SelectBox
            form={form}
            fieldName="clientCode"
            placeholder="Client"
            options={clients}
            rules={[
              { required: true, message: 'Please select a client' },
            ]}
            initialValue={editingQuotation ? editingQuotation.clientCode : ''}
            disabled={editingQuotation}
          />
          <InputElem
            form={form}
            fieldName="pojectTitle"
            rules={[
              { required: true, message: 'Please enter a title for the project.' },
            ]}
            placeholder="Project Title"
            type="text"
            initialValue={editingQuotation ? editingQuotation.title : ''}
          />
          <InputElem
            area
            form={form}
            fieldName="pojectScope"
            rules={[
              { required: true, message: 'Please enter a scope for the project.' },
            ]}
            placeholder="Scope of Work"
            type="text"
            initialValue={editingQuotation ? editingQuotation.scope : 'This quotation covers costs involved with developments of above application.'}
          />
          <div className="locked-options">
            <ModificationWarning />
            <FinancialSet
              financialItems={financialItems}
              SetFinancialItems={SetFinancialItems}
              financialError={financialError}
            />
          </div>
          <ReleasePlanSet
            releasePlans={releasePlans}
            SetReleasePlans={SetReleasePlans}
            releasePlanError={releasePlanError}
            getRandomKey={getRandomKey}
            setReleasePlanError={setReleasePlanError}
          />
          <div className="locked-options">
            <ModificationWarning />
            <PaymentPlanSet
              paymentPlans={paymentPlans}
              SetPaymentPlans={SetPaymentPlans}
              paymentError={paymentError}
              getRandomKey={getRandomKey}
              setPaymentError={setPaymentError}
            />
          </div>
          <InputElem
            area
            form={form}
            fieldName="projectTerms"
            placeholder="Terms"
            type="text"
            initialValue={editingQuotation ? editingQuotation.terms : 'Invoices to be settled within 07 calendar days (1 Week) from invoice date.'}
          />
          <Form.Item className="no-bottom-gutter">
            <Button type="primary" htmlType="submit" className="clients-form-button button-right" size="large" loading={isSubmitting}>
              {formSubmitButtonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

QuotationForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const QuotationFormWrapper = Form.create({ name: 'quotations-form' })(QuotationForm);

export default QuotationFormWrapper;
