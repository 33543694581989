import React from 'react';
import PropTypes from 'prop-types';

import Controllers from './controllers';

import './styles.css';

const InputList = (props) => {
  const { items, addItem, removeItem } = props;

  return items.map((item, index) => (
    <div className="input-group" key={item.key}>
      {item.body}
      <Controllers
        index={index}
        identifier={item.key}
        count={items.length}
        addItem={addItem}
        removeItem={removeItem}
      />
    </div>
  ));
};

InputList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    body: PropTypes.element,
  })).isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default InputList;
