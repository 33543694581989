import React, {
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import CurrencyMenu from '../../menu/currency';
import ProfileMenu from '../../menu/profile';
import RouteRegistry from '../../../router/registry';
import goHere from '../../../router/go-here';
import './index.css';

const Header = ({ setCurrency, currency, authService }) => {
  const history = useHistory();
  // const authService = AuthService.useContainer();
  const [currentPathname, setCurrentPathName] = useState('');

  const updateCurrentPathTitle = (path) => {
    const selectedPath = find(RouteRegistry, (regRoute) => {
      return regRoute.path === path;
    });

    if (!selectedPath) {
      goHere(history, RouteRegistry.dashboard.path);
      return;
    }

    if (selectedPath.type === 'sub') {
      const parent = RouteRegistry[selectedPath.parent];
      const pathname = `${parent.title} / ${selectedPath.ownTitle}`;
      setCurrentPathName(pathname);
    } else {
      setCurrentPathName(selectedPath.title);
    }
  };

  const setCurrencyHeader = (newCurrency) => {
    setCurrency(newCurrency);
  };

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.registryPathname) {
        updateCurrentPathTitle(history.location.state.registryPathname);
      } else {
        updateCurrentPathTitle(history.location.pathname);
      }
    } else {
      updateCurrentPathTitle(history.location.pathname);
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  if (!authService.isAuthenticated()) {
    return null;
  }

  return (
    <div className="header-holder">
      <div className="title-holder">
        <div className="title">
          {currentPathname}
        </div>
      </div>
      <div className="menu-holder">
        <div className="currency-holder">
          <CurrencyMenu
            setCurrency={setCurrencyHeader}
            currency={currency}
          />
        </div>
        <div className="profile-holder">
          <ProfileMenu authService={authService} />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  setCurrency: PropTypes.func.isRequired,
  currency: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  authService: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Header.defaultProps = {
  currency: false,
};

export default Header;
