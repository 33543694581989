/**
 * Create a url search params for get requests
 */
const castToURLQueryParamsString = (params) => {
  const queryArr = Object.keys(params).map((key) => {
    return (params[key] !== 0 && params[key] !== '' ? `${key}=${params[key]}` : '');
  });

  const queryString = queryArr.filter((arr) => {
    return arr !== '';
  })
    .join('&');

  return queryString;
};

/**
 * create a url search param object
 */
const castToURLSearchParams = (params) => {
  const bodyFormData = new URLSearchParams();
  Object.keys(params).forEach((key) => bodyFormData.append(key, params[key]));
  return bodyFormData;
};

export {
  castToURLQueryParamsString,
  castToURLSearchParams,
};
