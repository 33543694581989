import * as Storage from './storage';

const getCurrencyFromLocalStorage = () => {
  const currency = Storage.get(process.env.REACT_APP_CURRENCY_NAME);
  return currency ? currency.code : '';
};

const getQueryString = (string, key) => {
  return new URLSearchParams(string).get(key);
};

export {
  getCurrencyFromLocalStorage,
  getQueryString,
};
