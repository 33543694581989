import { notification } from 'antd';

const success = (title, message = '') => {
  notification.success({
    message: title,
    description: message,
    style: {
      background: '#cfffc9',
    },
  });
};

const error = (title, message = '') => {
  notification.error({
    message: title,
    description: message,
    style: {
      background: '#ff9e9e',
    },
  });
};

export default {
  success,
  error,
};
