import React, { useEffect, useState } from 'react';
import {
  Button,
  Tag,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  deleteServiceBill,
  getServiceBillStats,
  getServiceBillStatuses,
  queryServiceBills,
  // updateServiceBillStatus,
} from '../../../api/service-bill';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Enums from '../../../constants/enum';
import Table from '../../common/table';
import Notifications from '../../common/notifications';
import Icon from '../../common/icon';
import { thousandSeparator } from '../../../util/data/converter';
import { CategoryFilter } from './category-filter';
import FilterFormWrapper from './filter-form';
import PaymentWrapper from './payment-form';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import AuthContainer from '../../../containers/auth';
import './grid.css';

const { confirm } = Modal;

const InvoicesList = () => {
  const history = useHistory();
  const authService = AuthContainer.useContainer();

  const [invoices, setInvoices] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterObj, setFilterObj] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [bPayment, setBPayment] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [paidTotal, setPaidTotal] = useState(0.0);
  const [overDueAmount, setOverDueAmount] = useState(0.0);
  const [avgTimePay, setAvgTimePay] = useState(0);

  useEffect(() => {
    const getStats = async () => {
      const res = await getServiceBillStats();
      const { dueSum, invoiceSum, paidSum } = res.data;
      setPaidTotal(paidSum);
      setOverDueAmount(dueSum);
      const avgTimeToPay = (dueSum / invoiceSum) * 365;
      setAvgTimePay(Math.trunc(avgTimeToPay));
    };
    getStats();
  }, []);

  useEffect(() => {
    const gettingInvoices = async () => {
      try {
        setTableDataLoading(true);
        const res = await queryServiceBills({
          filterCategory,
        });
        const invoicesWithKey = res.data.map((invoice) => {
          return {
            key: invoice.id,
            ...invoice,
          };
        });
        setInvoices(invoicesWithKey);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load invoices!');
      }
    };
    const gettingStatus = async () => {
      try {
        const res = await getServiceBillStatuses();
        const settingStatuses = res.data.map((status) => {
          return {
            text: status.code,
            key: status.code,
          };
        });
        setStatuses(settingStatuses);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load statuses!');
      }
    };
    gettingStatus();
    gettingInvoices();
  }, [filterCategory]);

  const createInvoice = () => {
    goHere(history, RouteRegistry.invoiceCreate.path);
  };

  const viewServiceBill = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.invoice.path}/view/${id}`,
      state: {
        registryPathname: RouteRegistry.invoiceView.path,
      },
    });
  };

  const editServiceBill = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.invoice.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.invoiceUpdate.path,
      },
    });
  };

  const copyServiceBill = (id) => {
    goHere(history, `${RouteRegistry.invoiceCreate.path}?copyId=${id}`);
  };

  // const updateStatus = async (id, status) => {
  //   try {
  //     await updateServiceBillStatus(id, { status });
  //     const res = await queryServiceBills();
  //     setInvoices(res.data);
  //     Notifications.success(`Invoice status successfully changed to ${status}.`);
  //   } catch (error) {
  //     Notifications.error(`Something went wrong while trying to update status to ${status}`);
  //   }
  // };

  // const confirmStatusChange = (id, status) => {
  //   confirm({
  //     title: `Are you sure you want ${status} the invoice?`,
  //     okText: 'Yes',
  //     okType: 'success',
  //     cancelText: 'No',
  //     onOk() {
  //       updateStatus(id, status);
  //     },
  //   });
  // };

  const deleteQuot = async (id) => {
    try {
      await deleteServiceBill(id);
      const res = await queryServiceBills();
      setInvoices(res.data);
      Notifications.success('Invoice successfully deleted.');
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete Invoice.');
    }
  };

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this Invoice?',
      content: 'This action will remove all the associated data to this Invoice',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteQuot(id);
      },
    });
  };

  const DeleteAction = ({ record }) => {
    const { s_code: status, id } = record.record;
    if (
      authService.canDo(Enums.Sections.INVOICES, Enums.Access.DELETE)
      && status === Enums.ServiceBillStatus.DRAFT
    ) {
      return <Icon toolTip="Delete Invoice" icon="delete" onClick={() => confirmDelete(id)} />;
    }
    return null;
  };

  DeleteAction.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const payment = (record) => {
    setSelectedInvoice(record);
    setBPayment(true);
  };

  const closeInvoicing = () => {
    setBPayment(false);
    setSelectedInvoice({});
  };

  const amountFormat = (amount) => {
    const prasedAmount = parseFloat(amount).toFixed(Enums.Formats.AmountPrecision);
    return thousandSeparator(prasedAmount);
  };

  const GridActions = (record) => {
    const { id, s_code: status } = record.record;
    if (status === Enums.ServiceBillStatus.DRAFT) {
      return (
        <div className="grid-action-row">
          <Icon toolTip="Complete Invoice" icon="file-done" onClick={() => editServiceBill(id, Enums.ServiceBillStatus.UNSENT)} />
        </div>
      );
    }
    return (
      <div className="grid-action-row">
        {
          authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.READ)
          && <Icon toolTip="View Invoice(PDF)" icon="eye" onClick={() => viewServiceBill(id)} />
        }
        {
          authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.UPDATE)
          && status === Enums.ServiceBillStatus.DRAFT
          && <Icon toolTip="Edit Invoice" icon="form" onClick={() => editServiceBill(id)} />
        }
        {
          authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.CREATE)
          && <Icon toolTip="Duplicate Invoice" icon="copy" onClick={() => copyServiceBill(id)} />
        }
        {
          authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.UPDATE)
          && status !== Enums.ServiceBillStatus.PAID
          && <Icon toolTip="Record Payment" icon="dollar" onClick={() => payment(record)} />
        }
        {
          authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.READ)
          && <Icon toolTip="Export Invoice (PDF)" icon="export" onClick={() => viewServiceBill(id)} />
        }
        <DeleteAction record={record} />
      </div>
    );
  };

  const applyFilter = async (newFilterObj) => {
    try {
      const tempFilter = { ...filterObj };
      const updatedFilterObj = {
        ...tempFilter,
        ...newFilterObj,
      };
      setTableDataLoading(true);
      const response = await queryServiceBills(updatedFilterObj);
      setFilterObj(updatedFilterObj);
      const invoicesWithKey = response.data.map((invoice) => {
        return {
          key: invoice.id,
          ...invoice,
        };
      });
      setInvoices(invoicesWithKey);
      setTableDataLoading(false);
    } catch (error) {
      setTableDataLoading(false);
      Notifications.error('Someting went wrong while trying process your query.');
    }
  };

  const filterCategoryChange = (category) => {
    setFilterCategory(category);
    applyFilter({ filterCategory: category });
  };

  const getTag = (record) => {
    const status = record.s_code;
    let tagColor = '';
    switch (status) {
      case Enums.ServiceBillStatus.DRAFT:
        tagColor = 'gray'; break;
      case Enums.ServiceBillStatus.UNSENT:
        tagColor = 'orange'; break;
      case Enums.ServiceBillStatus.SENT:
        tagColor = 'blue'; break;
      case Enums.ServiceBillStatus.PAID:
        tagColor = 'green'; break;
      case Enums.ServiceBillStatus.OVERDUE:
        tagColor = 'red'; break;
      default:
        break;
    }
    return (
      <Tag color={tagColor} key={record.id}>
        {status.replace(/([A-Z])/g, ' $1')}
      </Tag>
    );
  };

  const columns = [
    {
      title: 'Status',
      render: (record) => {
        return (
          <>{getTag(record)}</>
        );
      },
      align: 'center',
    },
    {
      title: 'Invoice Date',
      key: 'invoiceDate',
      render: (record) => {
        return moment(record.invoiceDate).format(Enums.Formats.DateFormat);
      },
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      render: (record) => {
        return (moment(record.dueDate).format(Enums.Formats.DateFormat));
      },
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNo',
      render: (record) => {
        return (<p className="amount-col">{record.invoiceNo}</p>);
      },
    },
    {
      title: 'Client',
      key: 'c_code',
      dataIndex: 'c_code',
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Paid Amount',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Amount Due',
      dataIndex: 'dueAmount',
      key: 'dueAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 300,
      render: (record) => {
        return (<GridActions record={record} />);
      },
    },
  ];

  const getPaymentForm = () => {
    if (!bPayment) {
      return null;
    }
    return (
      <PaymentWrapper
        bPayment={bPayment}
        record={selectedInvoice}
        closeInvoicing={closeInvoicing}
        statuses={statuses}
        applyFilter={applyFilter}
      />
    );
  };

  const WidgetItem = ({ title, value, customClass }) => {
    return (
      <div className="widget-item-invoice">
        <div className={customClass} />
        <span>{title}</span>
        <p className="widget-amount">
          {value}
        </p>
      </div>
    );
  };

  WidgetItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    customClass: PropTypes.string.isRequired,
  };

  return (
    <>
      <div className="widget-holder-invoice">
        {paidTotal ? <WidgetItem title="TOTAL PAID AMOUNT" value={`${getCurrencyFromLocalStorage()} ${amountFormat(paidTotal)}`} customClass="widget-ribbon-blue" /> : null}
        {overDueAmount ? <WidgetItem title="OVERDUE AMOUNT" value={`${getCurrencyFromLocalStorage()} ${amountFormat(overDueAmount)}`} customClass="widget-ribbon-green" /> : null}
        {avgTimePay ? <WidgetItem title="AVERAGE TIME TO GET PAID" value={`${avgTimePay} days`} customClass="widget-ribbon-green" /> : null}
      </div>
      <div className="content-container">
        {authService.canDo(Enums.Sections.SERVICEBILLS, Enums.Access.CREATE)
        && (
          <div className="create-invoice-btn-container">
            <Button type="primary" icon="plus" size="large" onClick={createInvoice}>
              Create an invoice
            </Button>
          </div>
        )}
        <CategoryFilter
          filterCategory={filterCategory}
          categroyChange={filterCategoryChange}
        />
        <FilterFormWrapper
          filterObj={filterObj}
          applyFilter={applyFilter}
          setFilterObj={setFilterObj}
          statuses={statuses}
          invoiceNo={invoices}
          filterCategory={filterCategory}
        />
        {getPaymentForm()}
        <Table
          columns={columns}
          dataSource={invoices}
          loading={tableDataLoading}
        />
      </div>
    </>
  );
};

export default InvoicesList;
