import CountryData from '../../assets/data/countries.json';

const Countries = [];

for (let i = 0; i < CountryData.data.length; i += 1) {
  const temp = CountryData.data[i];
  temp.flag = temp.flag.toLowerCase();

  Countries.push(temp);
}

export default Countries;
