import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Form,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import {
  InputElem,
  SelectBox,
  DatePicker,
} from '../../common/form-elements';
import Notifications from '../../common/notifications';
import { invoice } from '../../../api/invoice';
import Enums from '../../../constants/enum';

const Invoice = ({
  form,
  bInvoicing,
  record,
  closeInvoicing,
  statuses,
  applyFilter,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goingWithPartiallyInvoiced, setGoingWithPartiallyInvoiced] = useState(false);
  const [notInvoicing, setNotInvoicing] = useState(false);
  const InvoiceFormRef = useRef(null);

  useEffect(() => {
    if (record.s_code === Enums.InvoiceStatus.HOLD
      || record.s_code === Enums.InvoiceStatus.IGNORE) {
      setNotInvoicing(true);
    }
  }, []);

  const save = async () => {
    setIsSubmitting(true);
    const formFieldValues = form.getFieldsValue();
    let { invoicingAmount } = formFieldValues;
    if (notInvoicing) {
      invoicingAmount = 0;
    }
    try {
      const prepObj = {
        id: record.id,
        invoicingAmount,
        status: formFieldValues.invoiceStatus,
        note: formFieldValues.invoiceNote,
        nextInvoiceDate: formFieldValues.nextInvoiceDate,
        invoicedDate: formFieldValues.invoicedDate,
      };
      await invoice(prepObj);
      Notifications.success('Successfully invoiced!');
      setIsSubmitting(false);
      closeInvoicing();
      applyFilter({});
    } catch (error) {
      setIsSubmitting(false);
      Notifications.error('Something went wrong while invoicing!');
    }
  };

  const close = () => {
    closeInvoicing();
  };

  const invoiceSave = async (e) => {
    e.preventDefault();
    form.validateFields((err) => {
      if (!err) {
        save();
      }
    });
  };

  const dateValidator = (rule, value, callback) => {
    try {
      if (!value) {
        throw new Error('Pick a next invoice date.');
      }
      const currentDate = moment().format('x');
      const selectedDateStamp = moment(value).format('x');
      if (selectedDateStamp <= currentDate) {
        throw new Error('Invalid next invoice date.');
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

  const notEmptyDateValidator = (rule, value, callback) => {
    try {
      if (!value) {
        throw new Error('Pick a next invoice date.');
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

  const getNextInvoiceDateField = () => {
    const rules = [
      {
        validator: dateValidator,
      },
    ];
    if (goingWithPartiallyInvoiced) {
      return (
        <DatePicker
          form={form}
          placeholder="Next Invoice Date"
          fieldName="nextInvoiceDate"
          initialValue={moment().startOf('day')}
          rules={rules}
        />
      );
    }
    return null;
  };

  const IsInvoiceAmountValid = (rule, value, callback) => {
    const formValues = form.getFieldsValue();
    const dueInvoice = (parseFloat(record.dueAmount) > 0);
    try {
      const invoicingAmount = parseFloat(value);
      const dueAmount = parseFloat(record.dueAmount);
      const invoiceAmount = parseFloat(record.invoiceAmount);
      if (formValues.invoiceStatus === Enums.InvoiceStatus.IGNORE
        || formValues.invoiceStatus === Enums.InvoiceStatus.HOLD) {
        if (!notInvoicing && invoicingAmount > 0) {
          throw new Error('Invoicing amount should be 0 for HOLD and IGNORE Statuses.');
        }
      }
      if (formValues.invoiceStatus === Enums.InvoiceStatus.COMPLETED) {
        if (dueInvoice) {
          if (invoicingAmount.toFixed(Enums.Formats.AmountPrecision)
            !== dueAmount.toFixed(Enums.Formats.AmountPrecision)) {
            throw new
            Error(`Invoicing amount should not exceed or be less than the due amount of this Due Invoice.
            Due amount for this Invoice is ${dueAmount.toFixed(Enums.Formats.AmountPrecision)}`);
          }
        }
        // else if (invoicingAmount.toFixed(Enums.Formats.AmountPrecision)
        //   !== invoiceAmount.toFixed(Enums.Formats.AmountPrecision)) {
        //   throw new
        //   Error('Invoicing amount should not exceed or be less than the Invoice amount.');
        // }
      }
      if (formValues.invoiceStatus === Enums.InvoiceStatus.PARTIALLYINVOICED) {
        if (dueInvoice) {
          if (invoicingAmount <= 0 || invoicingAmount >= dueAmount) {
            throw new
            Error(`Invoicing amount is not valid for this Due invoice with selected status PARTIALLY INVOICED.
            Select a valid Invoice amount. Due amount is ${parseFloat(dueAmount).toFixed(Enums.Formats.AmountPrecision)}`);
          }
        } else if (invoicingAmount <= 0 || invoicingAmount >= invoiceAmount) {
          throw new Error('Invoicing amount is not valid for selected status PARTIALLY INVOICED.');
        }
      }

      callback();
    } catch (error) {
      callback(error);
    }
  };

  const statusValidation = (rule, value, callback) => {
    try {
      if (value === Enums.InvoiceStatus.PENDING || value === Enums.InvoiceStatus.DUE) {
        throw new Error('Selected status is invlid to proceed with Invoicing, Please select a valid status.');
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

  const statusOnChange = (value) => {
    if (value === Enums.InvoiceStatus.PARTIALLYINVOICED) {
      setGoingWithPartiallyInvoiced(true);
    } else {
      setGoingWithPartiallyInvoiced(false);
    }
    if (value === Enums.InvoiceStatus.HOLD || value === Enums.InvoiceStatus.IGNORE) {
      setNotInvoicing(true);
      form.resetFields(['invoicingAmount']);
    } else {
      setNotInvoicing(false);
    }
  };

  const getPayableAmount = () => {
    if (!record) {
      return 0;
    }
    const dueInvoice = (parseFloat(record.dueAmount) > 0);
    if (dueInvoice) {
      return parseFloat(record.dueAmount).toFixed(Enums.Formats.AmountPrecision);
    }
    return parseFloat(record.invoiceAmount).toFixed(Enums.Formats.AmountPrecision);
  };

  const statusTips = () => {
    return (
      <>
        <p>
          Billar system does not generate actual invoices.
          At the moment system only allowed record each invoice status.
        </p>
        <p>Hold: When it&apos;s required to hold an invoice.</p>
        <p>
          Partially Invoiced: When partially invoiced.
          Remaining amount will be carried to a new invoice under the same quotation.
        </p>
        <p>Completed: At the event of fully invoice.</p>
        <p>Ignore: To discard or ignore the invoice.</p>
      </>
    );
  };

  return (
    <div>
      <Modal
        title="Invoice"
        visible={bInvoicing}
        okButtonProps={{ form: 'invoicing-form', key: 'submit', htmlType: 'submit' }}
        onCancel={close}
        confirmLoading={isSubmitting}
      >
        <Form onSubmit={invoiceSave} ref={InvoiceFormRef} id="invoicing-form">
          <InputElem
            placeholder="Original Invoice Amount"
            fieldName="invoiceAmount"
            form={form}
            initialValue={record ? record.invoiceAmount : ''}
            disabled
            number
          />
          <SelectBox
            placeholder="Status"
            form={form}
            fieldName="invoiceStatus"
            options={statuses}
            initialValue={record.s_code}
            rules={[
              {
                validator: statusValidation,
              },
            ]}
            onChange={statusOnChange}
            textValueSplit
            info={statusTips()}
          />
          <InputElem
            placeholder="Actual Invoice Amount"
            fieldName="invoicingAmount"
            form={form}
            initialValue={getPayableAmount()}
            type="number"
            rules={[
              { required: true, message: 'Please enter an invoicing amount.' },
              {
                validator: IsInvoiceAmountValid,
              },
            ]}
            number
            disabled={notInvoicing}
          />
          {getNextInvoiceDateField()}
          <DatePicker
            form={form}
            placeholder="Invoiced date"
            fieldName="invoicedDate"
            initialValue={moment(record.invoiceDate).startOf('day')}
            rules={[
              {
                validator: notEmptyDateValidator,
              },
            ]}
          />
          <InputElem
            placeholder="Note"
            fieldName="invoiceNote"
            form={form}
            area
            initialValue={record ? record.note : ''}
          />
        </Form>
      </Modal>
    </div>
  );
};

Invoice.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bInvoicing: PropTypes.bool.isRequired,
  record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  closeInvoicing: PropTypes.func.isRequired,
  statuses: PropTypes.oneOfType([PropTypes.array]).isRequired,
  applyFilter: PropTypes.func.isRequired,
};

const InvoiceWrapper = Form.create({ name: 'invoicing-form' })(Invoice);

export default InvoiceWrapper;
