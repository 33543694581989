import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Tag,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import Table from '../common/table';
import Notifications from '../common/notifications';
import { thousandSeparator } from '../../util/data/converter';
import Enums from '../../constants/enum';
import { dashboardInvoices } from '../../api/invoice';
import { getCurrencyFromLocalStorage } from '../../util/common-util';
import './index.css';

const Dashboard = () => {
  const [invoices, setInvoices] = useState([]);
  // const [invoiceSummary, setInvoiceSummary] = useState({});
  const [tableDataLoading, setTableDataLoading] = useState(false);
  useEffect(() => {
    const gettingInvoices = async () => {
      try {
        setTableDataLoading(true);
        const res = await dashboardInvoices({ currency: getCurrencyFromLocalStorage() });
        setInvoices(res.data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load invoices!');
      }
    };
    gettingInvoices();
  }, []);

  const getTag = (record) => {
    const status = record.s_code;
    let tagColor = '';
    switch (status) {
      case Enums.InvoiceStatus.PENDING:
        tagColor = 'orange'; break;
      case Enums.InvoiceStatus.PARTIALLYINVOICED:
        tagColor = 'blue'; break;
      case Enums.InvoiceStatus.DUE:
        tagColor = 'red'; break;
      case Enums.InvoiceStatus.COMPLETED:
        tagColor = 'green'; break;
      case Enums.InvoiceStatus.IGNORE:
        tagColor = 'gray'; break;
      default:
        break;
    }
    return (
      <Tag color={tagColor} key={record.id}>
        {status.replace(/([A-Z])/g, ' $1')}
      </Tag>
    );
  };

  const columns = [{
    title: 'Quotation',
    dataIndex: 'q_code',
    key: 'q_code',
  }, {
    title: 'Title',
    dataIndex: 'q_title',
    key: 'q_title',
    textWrap: 'word-break',
    width: 350,
  }, {
    title: 'Client',
    key: 'c_name',
    dataIndex: 'c_code',
    align: 'center',
  }, {
    title: 'InvoiceDate',
    key: 'invoiceDate',
    render: (record) => {
      return moment(record.invoiceDate).format(Enums.Formats.DateFormat);
    },
  }, {
    title: 'Amount',
    dataIndex: 'invoiceAmount',
    key: 'invoiceAmount',
    render: (value) => {
      const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
      return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
    },
  }, {
    title: 'Due',
    dataIndex: 'dueAmount',
    key: 'dueAmount',
    render: (value) => {
      const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
      return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
    },
  },
  {
    title: 'Status',
    render: (record) => {
      return (
        <>{getTag(record)}</>
      );
    },
    align: 'center',
  },
  ];

  const WidgetItem = ({ title, value }) => {
    return (
      <div className="widget-item">
        <div className="widget-ribbon" />
        <p>{title}</p>
        <p className="widget-amount">
          $
          {value}
        </p>
      </div>
    );
  };

  WidgetItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  // const thisMonthTotal = invoiceSummary.thisMonthTotal ?
  // parseFloat(invoiceSummary.thisMonthTotal)
  //   .toFixed(Enums.Formats.AmountPrecision) : 0.00;
  // const nextMonthTotal = invoiceSummary.nextMonthTotal ?
  // parseFloat(invoiceSummary.nextMonthTotal)
  //   .toFixed(Enums.Formats.AmountPrecision) : 0.00;
  // const thisYearTotal = invoiceSummary.thisYeahTotal ?
  // parseFloat(invoiceSummary.thisYeahTotal)
  //   .toFixed(Enums.Formats.AmountPrecision) : 0.00;
  return (
    <div>
      {/* <div className="widget-holder">
        <WidgetItem title="THIS MONTH INVOICE" value={thousandSeparator(thisMonthTotal)} />
        <WidgetItem title="NEXT MONTH INVOICE" value={thousandSeparator(nextMonthTotal)} />
        <WidgetItem title="THIS YEAR TOTAL" value={thousandSeparator(thisYearTotal)} />
      </div> */}
      <div className="content-container">
        <p className="dashboard-title">Upcoming Invoices</p>
        <Divider />
        <Table
          columns={columns}
          dataSource={invoices}
          loading={tableDataLoading}
        />
      </div>
    </div>
  );
};

export default Dashboard;
