import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Transfer,
  Tag,
  Table,
  Checkbox,
  Spin,
  Alert,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputElem,
  SelectBox,
} from '../../common/form-elements';
import PermissionModal from './permission';
import Notifcations from '../../common/notifications';
import {
  createUser,
  getUserById,
  updateUser,
} from '../../../api/user-permission';
import getRoles from '../../../api/role';
import getSections from '../../../api/section';
import { getClientsForFilter } from '../../../api/client';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import AuthContainer from '../../../containers/auth';
import Enums from '../../../constants/enum';
import 'antd/dist/antd.css';
import './index.css';

const UserForm = ({ form, match }) => {
  const history = useHistory();
  const authService = AuthContainer.useContainer();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingUser, setExistingUser] = useState(null);
  const [existingAuth0User, setExistingAuth0User] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [assignedSections, setAssignedSections] = useState([]);
  const [assignedSectionsOnMove, setAssignedSectionsOnMove] = useState([]);
  const [availableSections, setAvailableSections] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);
  const [availableClients, setAvailableClients] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const InitData = async () => {
      setLoading(true);
      let selectedUser = null;
      let userRole = null;
      let userClients = [];
      let userPermissions = [];
      let userIsAdmin = false;
      if (match.params.id) {
        const resUser = await getUserById(match.params.id);
        selectedUser = resUser.data;
        userRole = selectedUser.role;
        userClients = selectedUser.clients;
        userPermissions = selectedUser.sectionPermission;
        userIsAdmin = userRole === Enums.Roles.ADMIN;
        setIsAdmin(userRole === Enums.Roles.ADMIN);
        setExistingUser(selectedUser);
      }

      const resRoles = await getRoles({});
      const resSections = await getSections({});
      const resClients = await getClientsForFilter();

      if (resRoles) {
        const available = resRoles.data.map((role) => {
          return {
            text: role.name,
            key: role.name,
          };
        });

        setAvailableRoles(available);
      }

      if (resSections) {
        const assigened = [];
        const available = [];
        const permissionsList = [];

        resSections.data.forEach((section) => {
          const tempSection = {
            key: section.id,
            title: section.name,
            disabled: false,
          };
          available.push(tempSection);

          const tempPermission = selectedUser
            ? userPermissions.find((permission) => {
              return permission.section === section.name;
            })
            : null;

          if ((selectedUser && !userIsAdmin && tempPermission)) {
            assigened.push(tempSection.key);
            permissionsList.push({
              key: section.id,
              sectionName: tempPermission.section,
              sectionId: section.id,
              create: tempPermission.create,
              read: tempPermission.read,
              update: tempPermission.update,
              delete: tempPermission.delete,
            });
          }

          if (selectedUser && userIsAdmin) {
            assigened.push(tempSection.key);
            permissionsList.push({
              key: section.id,
              sectionName: section.name,
              sectionId: section.id,
              create: true,
              read: true,
              update: true,
              delete: true,
            });
          }
        });

        setAvailableSections(available);
        setAssignedSections(assigened);
        setPermissions(permissionsList);
      }

      if (resClients) {
        const assigened = [];
        const available = [];

        resClients.data.forEach((client) => {
          const tempClient = {
            key: client.id,
            title: client.code,
            disabled: false,
          };
          available.push(tempClient);

          if (selectedUser && !userIsAdmin && userClients.includes(client.code)) {
            assigened.push(tempClient.key);
          }

          if (selectedUser && userIsAdmin) {
            assigened.push(tempClient.key);
          }
        });

        setAvailableClients(available);
        setAssignedClients(assigened);
      }

      setLoading(false);
    };

    InitData();
    // eslint-disable-next-line
  }, []);

  const formSubmitButtonText = () => {
    if (existingUser) {
      return 'Update';
    }
    return 'Create';
  };

  const saveUser = async (values) => {
    try {
      setSubmitting(true);
      const userPermissions = permissions.map((permission) => {
        return {
          section: permission.sectionName,
          create: permission.create,
          read: permission.read,
          update: permission.update,
          delete: permission.delete,
        };
      });

      const userClients = [];
      availableClients.forEach((clients) => {
        if (assignedClients.includes(clients.key)) {
          userClients.push(clients.title);
        }
      });

      const userObj = {
        email: values.email,
        role: values.role,
        clients: userClients,
        sectionPermission: userPermissions,
      };

      if (existingUser) {
        const userObjWithId = { id: existingUser.id, ...userObj };
        await updateUser(userObjWithId);
        Notifcations.success('User successfully updated.');
        goHere(history, RouteRegistry.user.path);
      } else if (existingAuth0User) {
        await createUser(userObj);
        Notifcations.success('User Permissions successfully saved.');
        goHere(history, RouteRegistry.user.path);
      } else {
        const auth0Userobject = {
          email: values.email,
          password: values.password,
        };

        await authService.auth0SignUp(auth0Userobject, async (err, res) => {
          if (res) {
            Notifcations.success('Auth0 User successfully created.');
            await createUser(userObj);
            Notifcations.success('User Permissions successfully saved.');
            goHere(history, RouteRegistry.user.path);
          } else {
            Notifcations.error(`Auth0 User creation failed : ${err.description}`);
            setSubmitting(false);
          }
        });
      }
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 409) {
        Notifcations.error(error.response.data.message);
      } else {
        Notifcations.error('Something went wrong while managing your user.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveUser(values);
      }
    });
  };

  const handleExistingAuth0UserSelection = (e) => {
    const { checked } = e.target;
    setExistingAuth0User(checked);
  };

  const confirmPasswordMatch = () => {
    const { password, confirmPassword } = form.getFieldsValue();
    return password === confirmPassword;
  };

  const handleRoleChange = (role) => {
    setIsAdmin(role === Enums.Roles.ADMIN);
    if (role === Enums.Roles.ADMIN) {
      setAssignedClients(availableClients.map((client) => { return client.key; }));
      setAssignedSections(availableSections.map((section) => { return section.key; }));
      setPermissions(availableSections.map((section) => {
        return {
          key: section.key,
          sectionName: section.title,
          sectionId: section.key,
          create: true,
          read: true,
          update: true,
          delete: true,
        };
      }));
    } else {
      setAssignedClients([]);
      setAssignedSections([]);
      setPermissions([]);
    }
  };

  const handleSectionsChange = (nextTargetKeys, direction, moveKeys) => {
    if (direction === 'right') {
      const tempSelectedSections = availableSections.filter((item) => {
        return moveKeys.includes(item.key);
      });
      setSelectedSections(tempSelectedSections);
      setAssignedSectionsOnMove(nextTargetKeys);
    } else {
      const newPermissions = permissions.filter((currenctPermission) => {
        return !moveKeys.includes(currenctPermission.sectionId);
      });
      setPermissions(newPermissions);
      setAssignedSections(nextTargetKeys);
    }
  };

  const handleClientsChange = (nextTargetKeys) => {
    setAssignedClients(nextTargetKeys);
  };

  const sectionPermissionOK = (tempPermissions) => {
    if (tempPermissions) {
      setPermissions([...permissions, ...tempPermissions]);
      setAssignedSections(assignedSectionsOnMove);
      setSelectedSections([]);
    }
  };

  const sectionPermissionCancel = () => {
    setAssignedSectionsOnMove([]);
    setSelectedSections([]);
  };

  const changePermission = (action, record) => {
    const newRecord = record;
    newRecord[action] = !record[action];
    const newPermissions = permissions.slice();
    const index = newPermissions.indexOf(record);
    newPermissions[index] = newRecord;
    setPermissions(newPermissions);
  };

  const getTransferTag = (title, color) => {
    return <Tag color={color}>{title}</Tag>;
  };

  const columns = [
    {
      title: 'Section',
      dataIndex: 'sectionName',
      key: 'sectionName',
      align: 'left',
      render: (record) => { return getTransferTag(record, '#3a5069'); },
    },
    {
      title: 'Create',
      key: 'create',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            disabled={isAdmin}
            checked={record.create}
            onChange={() => changePermission('create', record)}
          />
        );
      },
    },
    {
      title: 'Read',
      key: 'read',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            disabled
            checked={record.read}
            onChange={() => changePermission('read', record)}
          />
        );
      },
    },
    {
      title: 'Update',
      key: 'update',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            disabled={isAdmin}
            checked={record.update}
            onChange={() => changePermission('update', record)}
          />
        );
      },
    },
    {
      title: 'Delete',
      key: 'delete',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            disabled={isAdmin}
            checked={record.delete}
            onChange={() => changePermission('delete', record)}
          />
        );
      },
    },
  ];

  return (
    <div className="content-container">
      <PermissionModal
        section={selectedSections}
        onSubmit={sectionPermissionOK}
        onCancel={sectionPermissionCancel}
      />
      <div className="form">
        <Spin tip="Loading..." spinning={isLoading}>
          <Form onSubmit={handleSubmit} className="user-form">
            <InputElem
              form={form}
              fieldName="email"
              rules={[
                { required: true, message: 'Please enter a Username(Email)' },
                { type: 'email', message: 'Enter a valid E-mail' },
              ]}
              placeholder="Username (Email)*"
              type="email"
              initialValue={existingUser ? existingUser.email : ''}
              autoComplete={false}
              disabled={existingUser !== null}
            />
            {
              !existingUser
              && (
                <div className="auth0-user-checkbox">
                  <Checkbox
                    checked={existingAuth0User}
                    onChange={handleExistingAuth0UserSelection}
                  >
                    Auth0 User Exist
                  </Checkbox>
                </div>
              )
            }
            {
              (!existingUser && !existingAuth0User)
              && (
                <>
                  <InputElem
                    form={form}
                    fieldName="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid password',
                        /* eslint-disable-next-line */
                        pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12}$',
                      },
                    ]}
                    placeholder="Password*"
                    type="password"
                    initialValue={existingUser || existingAuth0User ? null : ''}
                    autoComplete={false}
                  />
                  <InputElem
                    form={form}
                    fieldName="confirmPassword"
                    rules={[
                      {
                        validator: confirmPasswordMatch,
                        message: 'Password not matched',
                      },
                    ]}
                    placeholder="Confirm Password*"
                    type="password"
                    initialValue={existingUser || existingAuth0User ? null : ''}
                    autoComplete={false}
                  />
                </>
              )
            }
            <SelectBox
              form={form}
              fieldName="role"
              placeholder="Role"
              options={availableRoles}
              rules={[
                { required: true, message: 'Please select a role' },
              ]}
              initialValue={existingUser ? existingUser.role : ''}
              onChange={handleRoleChange}
            />
            {isAdmin && (
              <Alert
                message="Admin has full access to the whole system by default. You can not edit permissions for a Admin user. For customize the permissions, please select the Member role"
                type="info"
              />
            )}
            <span className="transfer-label">Allowed Sections</span>
            <Transfer
              dataSource={availableSections}
              titles={['Available Sections', 'Assigned Sections']}
              targetKeys={assignedSections}
              onChange={handleSectionsChange}
              render={(item) => getTransferTag(item.title, '#3a5069')}
              className="tree-transfer"
              disabled={isAdmin}
            />
            {permissions && permissions.length > 0 && (
              <>
                <span className="transfer-label">Permissions for assigned sections</span>
                <Table
                  columns={columns}
                  dataSource={permissions}
                  pagination={false}
                  disabled={isAdmin}
                />
              </>
            )}
            <span className="transfer-label">Clients</span>
            <Transfer
              dataSource={availableClients}
              titles={['Available Clients', 'Assigned Clients']}
              targetKeys={assignedClients}
              onChange={handleClientsChange}
              render={(item) => getTransferTag(item.title, '#3a5069')}
              className="tree-transfer"
              disabled={isAdmin}
            />
            <Form.Item className="form-bottom">
              <Button type="primary" htmlType="submit" className="user-form-button button-right" size="large" loading={isSubmitting}>
                {formSubmitButtonText()}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const UserFormWrapper = Form.create({ name: 'user-form' })(UserForm);

export default UserFormWrapper;
