import Enums from '../../constants/enum';

const thousandSeparator = (num) => {
  const numParts = num.toString().split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numParts.join('.');
};

const getSum = (values) => {
  return values.map((value) => (value && value !== '' ? parseFloat(value) : 0))
    .reduce((previousValue, currentValue) => (previousValue + currentValue))
    .toFixed(Enums.Formats.AmountPrecision);
};

export {
  thousandSeparator,
  getSum,
};
