import React, { useState } from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

import './index.css';

const ProfileMenu = ({ authService }) => {
  const [showMenu, setShowMenu] = useState(false);

  const logout = () => {
    authService.logOut();
  };

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
      return;
    }
    setShowMenu(true);
  };

  const handleKeyPress = () => {

  };

  const MenuItem = ({
    action,
    tabIndex,
    icon,
    title,
  }) => {
    return (
      <div className="menu-item" onClick={action} onKeyDown={action} role="button" tabIndex={tabIndex}>
        <Icon type={icon} className="menu-item-icon" />
        <p>{title}</p>
      </div>
    );
  };

  MenuItem.propTypes = {
    action: PropTypes.func.isRequired,
    tabIndex: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  const Menu = () => {
    const userinfo = authService.getUserInfo();
    const { username } = userinfo;
    const menuActiveClass = showMenu ? 'menu-content-holder-active' : 'menu-content-holder-inactive';
    return (
      <div className={`menu-content-holder ${menuActiveClass}`}>
        <p className="username-para">{username}</p>
        <div className="menu-items">
          <MenuItem action={logout} tabIndex="0" icon="logout" title="Logout" />
        </div>
      </div>
    );
  };
  const userinfo = authService.getUserInfo();
  return (
    <>
      <div className="profile" onClick={toggleMenu} role="button" tabIndex="0" onKeyPress={handleKeyPress}>
        <div className="avatar-holder">
          <img src={userinfo.userimage} alt="userimage" className="avatar-image" />
        </div>
        <Icon type="caret-down" onClick={toggleMenu} />
      </div>
      <Menu />
    </>
  );
};

ProfileMenu.propTypes = {
  authService: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProfileMenu;
