import { StyleSheet } from '@react-pdf/renderer';

const themeBlue = '#377ab9';

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
  },
  topHeaderRightTitle: {
    marginBottom: 5,
  },
  topHeaderRightTileSub: {
    fontSize: 11,
  },
  headerSection: {
    height: 'auto',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  headerRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerLogoLeft: {
    width: '150px',
  },
  headerTopicRight: {
    alignItems: 'flex-end',
  },
  headerAddressHeader: {
    fontSize: 11,
    fontWeight: 600,
    fontStyle: 'bold',
  },
  headerAddressField: {
    fontSize: 11,
    color: '#666666',
  },
  rightAlign: {
    alignItems: 'flex-end',
  },
  leftAlign: {
    alignItems: 'flex-start',
  },
  quotationMetaHolder: {
    backgroundColor: themeBlue,
    width: '100%',
    height: 15,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    padding: 10,
  },
  headerDateField: {
    fontSize: 11,
  },
  titleSection: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottm: 20,
    marginBottom: 20,
  },
  mainTitle: {
    color: themeBlue,
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
  },
  mainTitleSub: {
    fontSize: 18,
    color: themeBlue,
    marginTop: 20,
    marginBottom: 5,
  },
  subTitle: {
    color: themeBlue,
    fontSize: 18,
    marginBottom: 5,
  },
  mainTitleSubDesc: {
    fontSize: 11,
    color: '#666666',
  },
  divider: {
    width: '100%',
    height: 0.5,
    backgroundColor: 'black',
  },
  gridSection: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    paddingTop: 10,
  },
  gridTitle: {
    color: '#fff',
    fontSize: 14,
    backgroundColor: themeBlue,
    padding: 5,
    textAlign: 'center',
  },
  gridTop: {
    borderBottomWidth: 0.5,
  },
  customGridRow: {
    width: '100%',
    borderBottomWidth: 0.5,
    height: 'auto',
    flexDirection: 'row',
  },
  customGridCol: {
    borderLeftWidth: 0.5,
    padding: 5,
  },
  colHeader: {
    fontSize: 11,
    fontWeight: 'bold',
  },
  numberCol: {
    width: 30,
  },
  textCol: {
    width: 425,
  },
  midTextCol: {
    width: 195,
  },
  amountCol: {
    width: 100,
  },
  finalCol: {
    borderRightWidth: 0.5,
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  colData: {
    fontSize: 11,
    color: '#666666',
  },
  quaterCol: {
    width: '33.33%',
  },
  centerText: {
    textAlign: 'center',
  },
  totalCol: {
    backgroundColor: '#dae4f5',
  },
  finalSection: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontStyle: 'italic',
  },
  termsSectionTitle: {
    fontSize: 11,
    fontStyle: 'bold',
  },
  termsSectionTerms: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#666666',
  },
  totalColText: {
    color: '#000',
  },
});

export default styles;
