import React, { useEffect, useState } from 'react';
import {
  Modal,
  Table,
  Checkbox,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import './index.css';

const PermissionModal = ({ section, onSubmit, onCancel }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    onSubmit();
    onCancel();
  }, []);

  useEffect(() => {
    setPermissions(section.map((element) => {
      return {
        key: element.key,
        sectionName: element.title,
        sectionId: element.key,
        create: false,
        read: true,
        update: false,
        delete: false,
      };
    }));
  }, [section]);

  const grantPermissions = () => {
    onSubmit(permissions);
  };

  const getTransferTag = (title, color) => {
    return <Tag color={color}>{title}</Tag>;
  };

  const changePermission = (action, record) => {
    const newRecord = record;
    newRecord[action] = !record[action];
    const newPermissions = permissions.slice();
    const index = newPermissions.indexOf(record);
    newPermissions[index] = newRecord;
    setPermissions(newPermissions);
  };

  const columns = [
    {
      title: 'Section',
      dataIndex: 'sectionName',
      key: 'sectionName',
      align: 'left',
      render: (record) => { return getTransferTag(record, '#3a5069'); },
    },
    {
      title: 'Create',
      key: 'create',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            checked={record.create}
            onChange={() => changePermission('create', record)}
          />
        );
      },
    },
    {
      title: 'Read',
      key: 'read',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            disabled
            checked={record.read}
            onChange={() => changePermission('read', record)}
          />
        );
      },
    },
    {
      title: 'Update',
      key: 'update',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            checked={record.update}
            onChange={() => changePermission('update', record)}
          />
        );
      },
    },
    {
      title: 'Delete',
      key: 'delete',
      align: 'center',
      render: (record) => {
        return (
          <Checkbox
            checked={record.delete}
            onChange={() => changePermission('delete', record)}
          />
        );
      },
    },
  ];

  return (
    <Modal
      visible={section.length > 0}
      closable={false}
      onCancel={onCancel}
      onOk={grantPermissions}
    >
      <Table
        columns={columns}
        dataSource={permissions}
        pagination={false}
        loading={section.length === 0}
      />
    </Modal>
  );
};

PermissionModal.propTypes = {
  section: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.func]).isRequired,
  onCancel: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default PermissionModal;
