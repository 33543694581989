import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const subPath = 'bank-details';

const getBanksDetails = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`${subPath}?${queryString}`);
  return response;
};

const getBanksDetailsWithClientCount = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`${subPath}/with-client-count?${queryString}`);
  return response;
};

const getBankDetails = async (id) => {
  const response = await axios.get(`${subPath}/${id}`);
  return response;
};

const createBankDetails = async (newBankDetails) => {
  const response = await axios.post(`${subPath}`, newBankDetails);
  return response;
};

const updateBankDetails = async (updatedBankDetails) => {
  const response = await axios.put(`${subPath}`, updatedBankDetails);
  return response;
};

const deleteBankDetails = async (id) => {
  const response = await axios.delete(`${subPath}/${id}`);
  return response;
};

export {
  getBanksDetails,
  getBanksDetailsWithClientCount,
  getBankDetails,
  createBankDetails,
  updateBankDetails,
  deleteBankDetails,
};
