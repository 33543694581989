import React, { useState } from 'react';
import moment from 'moment';
import {
  Form,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import {
  InputElem,
  DatePicker,
} from '../../common/form-elements';
import Notifications from '../../common/notifications';
import { newinvoice } from '../../../api/invoice';

const InvoiceForm = ({
  form,
  bNewInvoice,
  record,
  closeInvoiceForm,
  applyFilter,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const save = async () => {
    setIsSubmitting(true);
    const formFieldValues = form.getFieldsValue();
    try {
      const prepObj = {
        quotationId: record.q_id,
        invoiceAmount: formFieldValues.invoiceAmount,
        invoiceDate: formFieldValues.invoiceDate,
        note: formFieldValues.invoiceNote,
        description: formFieldValues.description,
      };
      await newinvoice(prepObj);
      Notifications.success('Invoice successfully created.');
      setIsSubmitting(false);
      closeInvoiceForm();
      applyFilter({});
    } catch (error) {
      setIsSubmitting(false);
      Notifications.error('Something went wrong while creating Invoice.');
    }
  };

  const close = () => {
    closeInvoiceForm();
  };

  const invoiceSave = async (e) => {
    e.preventDefault();
    form.validateFields((err) => {
      if (!err) {
        save();
      }
    });
  };

  const dateValidator = (rule, value, callback) => {
    try {
      if (!value) {
        throw new Error('Pick an Invoice Date.');
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

  const quotationCode = record ? record.q_code : '';
  return (
    <div>
      <Modal
        title={`New Invoice for - ${quotationCode}`}
        visible={bNewInvoice}
        okButtonProps={{ form: 'invoice-form', key: 'submit', htmlType: 'submit' }}
        onCancel={close}
        confirmLoading={isSubmitting}
      >
        <Form onSubmit={invoiceSave} id="invoice-form">
          <InputElem
            type="number"
            placeholder="Invoice Amount"
            fieldName="invoiceAmount"
            form={form}
            rules={[
              { required: true, message: 'Please enter an Invoice amount.' },
            ]}
            number
          />
          <DatePicker
            form={form}
            placeholder="Invoice Date"
            fieldName="invoiceDate"
            initialValue={moment().startOf('day')}
            rules={[
              {
                validator: dateValidator,
              },
            ]}
          />
          <InputElem
            placeholder="Description"
            fieldName="description"
            form={form}
            area
          />
          <InputElem
            placeholder="Note"
            fieldName="invoiceNote"
            form={form}
            area
          />
        </Form>
      </Modal>
    </div>
  );
};

InvoiceForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bNewInvoice: PropTypes.bool.isRequired,
  record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  closeInvoiceForm: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
};

const InvoiceFormWrapper = Form.create({ name: 'invoice-form' })(InvoiceForm);

export default InvoiceFormWrapper;
