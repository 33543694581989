import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes, { arrayOf, bool } from 'prop-types';
import { Icon } from 'antd';

const checkChildClass = (element, className) => {
  if (element) {
    const childElems = element.childNodes[0].childNodes;
    if (childElems.length > 0) {
      const found = [];
      childElems.forEach((elem) => {
        if (elem.classList.contains(className)) {
          found.push(elem);
        }
      });
      if (found.length > 0) {
        return true;
      }
    }
  }
  return false;
};

const SidebarCollpesItem = ({ children, collapseTitle, collapsedIcon }) => {
  let childrenLength = 0;
  const [isOpen, setIsOpen] = useState();
  const [height, setHeight] = useState();
  const sidebarRouteHeight = 70;
  const childRouteContainer = useRef(null);

  if ((typeof children) === 'boolean') {
    childrenLength = 0;
  } else if (children.length === undefined) {
    childrenLength = 1;
  } else {
    childrenLength = children.filter((c) => { return c !== false; }).length;
  }

  const toggleCollapseMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      setHeight(0);
      console.log('coming here');
      return;
    }

    const fullHeight = sidebarRouteHeight * (childrenLength);
    setHeight(`${fullHeight}px`);
    setIsOpen(true);
  };

  useEffect(() => {
    const found = checkChildClass(childRouteContainer.current, 'sidebar-route-active');
    if (found && !isOpen) {
      toggleCollapseMenu();
    }
    // eslint-disable-next-line
  }, [childRouteContainer]);

  const onDownClick = () => {
    toggleCollapseMenu();
  };

  const handleKeyPress = () => {

  };

  const childRouteClass = isOpen ? 'child-routes-show' : 'child-routes-hide';
  const carretClass = isOpen ? 'carret-up' : 'carret-down';
  return (childrenLength > 0
    ? (
      <div className="sidebar-collapes-item" key="1">
        <div className="collapes-title" onClick={onDownClick} role="button" tabIndex="0" onKeyPress={handleKeyPress}>
          <Icon type={collapsedIcon} className="sidebar-item-icon" />
          {collapseTitle}
          <Icon
            type="down"
            className={`collapes-title-down ${carretClass}`}
            onClick={onDownClick}
          />
        </div>
        <div
          className="child-route-container"
          style={{ height }}
          ref={childRouteContainer}
        >
          <div className={`child-routes ${childRouteClass}`}>
            {children}
          </div>
        </div>
      </div>
    )
    : null
  );
};

SidebarCollpesItem.propTypes = {
  children: PropTypes.oneOfType([
    arrayOf(PropTypes.node),
    arrayOf(bool),
    bool,
    PropTypes.node,
  ]).isRequired,
  collapseTitle: PropTypes.string.isRequired,
  collapsedIcon: PropTypes.string.isRequired,
};

export default SidebarCollpesItem;
