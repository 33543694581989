import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as AntTable,
} from 'antd';

import './index.css';

const Table = ({ columns, dataSource, loading }) => {
  return (
    <div className="table-wrapper">
      <AntTable columns={columns} dataSource={dataSource} loading={loading} bordered />
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.array]).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Table;
