import axios from './api-controller';

const getLogs = async (queryData) => {
  const response = await axios.post('/actionlogs/query', queryData);
  return response;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getLogs,
};
