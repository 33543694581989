import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const subPath = 'user';

const getUsers = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`${subPath}${queryString}`);
  return response;
};

const getUserById = async (id) => {
  const response = await axios.get(`${subPath}/${id}`);
  return response;
};

const getUserProfile = async () => {
  const response = await axios.get(`${subPath}/profile/complete`);
  return response;
};

const createUser = async (newUser) => {
  const response = await axios.post(`${subPath}`, newUser);
  return response;
};

const updateUser = async (newUser) => {
  const response = await axios.put(`${subPath}/${newUser.id}`, newUser);
  return response;
};

const deleteUser = async (id) => {
  const response = await axios.delete(`${subPath}/${id}`);
  return response;
};

export {
  getUsers,
  getUserById,
  getUserProfile,
  createUser,
  updateUser,
  deleteUser,
};
