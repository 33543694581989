import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Modal,
  Tag,
  Divider,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import AuthContainer from '../../../containers/auth';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import {
  getUsers,
  deleteUser,
} from '../../../api/user-permission';
import Enums from '../../../constants/enum';
import './grid.css';

const UserGrid = () => {
  const authService = AuthContainer.useContainer();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [currenctUser, setCurrenctUser] = useState({});
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const createUser = () => {
    goHere(history, RouteRegistry.userCreate.path);
  };

  useEffect(() => {
    const gettingUsers = async () => {
      try {
        setTableDataLoading(true);
        const res = await getUsers({});
        setUsers(res.data.map((user) => {
          return {
            key: user.id,
            ...user,
          };
        }));
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load Users!');
      }
    };
    gettingUsers();

    setCurrenctUser(authService.getUserPermissions());
  }, []);

  const editUser = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.user.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.userUpdate.path,
      },
    });
  };

  const deleteSelectedUser = async (id) => {
    try {
      setTableDataLoading(true);
      await deleteUser(id);
      const resUsers = await getUsers({});
      setUsers(resUsers.data);
      Notifications.success('User successfully deleted.');
      setTableDataLoading(false);
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete User.');
      setTableDataLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Delete User?',
      content: 'Are you sure you want to delete this User?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSelectedUser(id);
      },
    });
  };

  const GridActions = ({ record }) => {
    return (record.email !== currenctUser.email
      ? (
        <div className="grid-action-row">
          <Icon type="form" className="grid-action-icon" onClick={() => { editUser(record.id); }} />
          <Icon type="delete" className="grid-action-icon" onClick={() => { confirmDelete(record.id); }} />
        </div>
      )
      : <Tag color="#f5222d">Active User</Tag>
    );
  };

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
    },
    {
      title: 'Role',
      key: 'role',
      align: 'center',
      width: 200,
      render: (record) => {
        return (record.role === Enums.Roles.ADMIN
          ? <Tag color="#1890ff">{record.role}</Tag>
          : <Tag color="#797979">{record.role}</Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 300,
      render: (record) => {
        return (<GridActions record={record} />);
      },
    },
  ];

  return (
    <div className="content-container">
      <div className="create-user-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={createUser}>
          Add New User
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={users}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default UserGrid;
