import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputElem,
} from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import {
  getBankDetails,
  createBankDetails,
  updateBankDetails,
} from '../../../api/bank-details';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import 'antd/dist/antd.css';
import './index.css';

const BankDetailsForm = ({ form, match }) => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingBankDetails, setExistingBankDetails] = useState(null);

  useEffect(() => {
    const getSelectedBankDetails = async () => {
      if (match.params.id) {
        const res = await getBankDetails(match.params.id);
        setExistingBankDetails(res.data);
      }
    };

    getSelectedBankDetails();
    // eslint-disable-next-line
  }, [])

  const saveBankDetails = async (values) => {
    try {
      setSubmitting(true);
      const bankDetailsObj = {
        name: values.name,
        branch: values.branch,
        address: values.address,
        accountNumber: values.accountNumber,
        swiftCode: values.swiftCode,
      };
      if (existingBankDetails) {
        const BankDetailsObjWithId = { id: existingBankDetails.id, ...bankDetailsObj };
        await updateBankDetails(BankDetailsObjWithId);
        Notifcations.success('Bank details successfully updated.');
      } else {
        await createBankDetails(bankDetailsObj);
        Notifcations.success('Bank details successfully added.');
      }
      setSubmitting(false);
      goHere(history, RouteRegistry.bankDetails.path);
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 409) {
        Notifcations.error(error.response.data.message);
      } else {
        Notifcations.error('Something went wrong while managing your bank details.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveBankDetails(values);
      }
    });
  };

  const formSubmitButtonText = () => {
    if (existingBankDetails) {
      return 'Update';
    }
    return 'Create';
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form onSubmit={handleSubmit} className="clients-form">
          <InputElem
            form={form}
            fieldName="name"
            rules={[
              { required: true, message: 'Please enter bank name' },
            ]}
            placeholder="Bank Name*"
            type="text"
            initialValue={existingBankDetails ? existingBankDetails.name : null}
          />
          <InputElem
            form={form}
            fieldName="branch"
            rules={[
              { required: true, message: 'Please enter the branch name' },
            ]}
            placeholder="Branch*"
            type="text"
            initialValue={existingBankDetails ? existingBankDetails.branch : null}
          />
          <InputElem
            form={form}
            fieldName="address"
            placeholder="Branch Address"
            type="text"
            initialValue={existingBankDetails ? existingBankDetails.address : ''}
            area
          />
          <InputElem
            form={form}
            fieldName="accountNumber"
            rules={[
              { required: true, message: 'Please enter account number' },
            ]}
            placeholder="Account Number*"
            type="text"
            initialValue={existingBankDetails ? existingBankDetails.accountNumber : null}
          />
          <InputElem
            form={form}
            fieldName="swiftCode"
            rules={[
              { required: true, message: 'Please enter swift code' },
            ]}
            placeholder="Swift Code*"
            type="text"
            initialValue={existingBankDetails ? existingBankDetails.swiftCode : null}
          />
          <Form.Item className="no-bottom-gutter">
            <Button type="primary" htmlType="submit" className="bank-details-form-button button-right" size="large" loading={isSubmitting}>
              {formSubmitButtonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

BankDetailsForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const BankDetailsFormWrapper = Form.create({ name: 'bank-details-form' })(BankDetailsForm);

export default BankDetailsFormWrapper;
