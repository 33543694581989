import React, { useEffect } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const FilterCategory = Object.freeze({
  All: 'all',
  UnPaid: 'unPaid',
  Draft: 'draft',
});

const CategoryFilter = ({
  filterCategory,
  categroyChange,
}) => {
  useEffect(() => {
    categroyChange(FilterCategory.All);
  }, []);

  const onFilterChanged = (e) => {
    categroyChange(e.target.value);
  };

  const getRadioOption = (value, text) => {
    const checkedValue = (filterCategory === value);
    return (
      <Radio.Button
        value={value}
        onChange={onFilterChanged}
        checked={checkedValue}
      >
        {text}
      </Radio.Button>
    );
  };

  return (
    <div className="category-filter-holder">
      <Radio.Group defaultValue={filterCategory} buttonStyle="solid">
        {getRadioOption(FilterCategory.All, 'All')}
        {getRadioOption(FilterCategory.UnPaid, 'Un Paid')}
        {getRadioOption(FilterCategory.Draft, 'Draft')}
      </Radio.Group>
    </div>
  );
};

CategoryFilter.propTypes = {
  filterCategory: PropTypes.string.isRequired,
  categroyChange: PropTypes.func.isRequired,
};

export {
  CategoryFilter,
  FilterCategory,
};
