import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Tag,
  Modal,
  Divider,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import PageReload from '../page-reload-message';
import {
  deleteCurrency,
  getCurrenciesWithQuotataionCount,
} from '../../../api/currency';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import './grid.css';

const CurrencyGrid = () => {
  const history = useHistory();
  const [currencies, setCurrencies] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const createClient = () => {
    goHere(history, RouteRegistry.currencyCreate.path);
  };

  useEffect(() => {
    const gettingClients = async () => {
      try {
        setTableDataLoading(true);
        const res = await getCurrenciesWithQuotataionCount({});
        setCurrencies(res.data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load currencies!');
      }
    };
    gettingClients();
  }, []);

  const editCurrency = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.currency.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.currencyUpdate.path,
      },
    });
  };

  const deleteSelectedCurrency = async (id) => {
    try {
      setTableDataLoading(true);
      await deleteCurrency(id);
      // const res = await getCurrenciesWithQuotataionCount({});
      // setCurrencies(res.data);
      Notifications.success('Currency successfully deleted.');
      setTableDataLoading(false);
      PageReload(() => { });
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete Currency.');
      setTableDataLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Delete Currency?',
      content: 'Are you sure you want to delete this Currency?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSelectedCurrency(id);
      },
    });
  };

  const GridActions = ({ record }) => {
    if (record.code === getCurrencyFromLocalStorage() || record.quotationCount !== 0) {
      return (
        <div className="grid-action-row">
          {record.code === getCurrencyFromLocalStorage() && <Tag color="#1890ff">Active Currency</Tag>}
          {record.quotationCount !== 0 && <Tag color="#f5222d">Used in Quotations</Tag>}
        </div>
      );
    }
    return (
      <div className="grid-action-row">
        <Icon type="form" className="grid-action-icon" onClick={() => { editCurrency(record.id); }} />
        <Icon type="delete" className="grid-action-icon" onClick={() => { confirmDelete(record.id); }} />
      </div>
    );
  };

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 300,
      render: (record) => {
        return (<GridActions record={record} />);
      },
    },
  ];

  return (
    <div className="content-container">
      <div className="create-currency-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={createClient}>
          Add New Currency
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={currencies}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default CurrencyGrid;
