const set = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    // console.error('error occured while setting storageobject');
    return false;
  }
};

const get = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    // console.error('error occured while getting storageobject');
    return false;
  }
};

const remove = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    // console.error('error occured while removing storageobject');
    return false;
  }
};

export { set, get, remove };
