import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const Controllers = (props) => {
  const {
    index, identifier, count, addItem, removeItem,
  } = props;

  const onAddClick = () => {
    addItem(uuidv4());
  };

  if (index === 0 && count === 1) {
    return <Icon type="plus" onClick={onAddClick} className="control-icon" />;
  }
  if (count - 1 === index && count > 1) {
    return (
      <>
        <Icon type="delete" onClick={() => removeItem(identifier)} className="control-icon" />
        <Icon type="plus" onClick={onAddClick} className="control-icon" />
      </>
    );
  }
  return (
    <Icon type="delete" onClick={() => removeItem(identifier)} className="control-icon" />
  );
};

Controllers.propTypes = {
  index: PropTypes.number.isRequired,
  identifier: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default Controllers;
