import React, { useState } from 'react';
import {
  List,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';

const LoadClientList = ({
  open,
  records,
  close,
  selectedClientCallback,
}) => {
  const [selectedClient, setSelectedClient] = useState('');

  const closeModal = () => {
    close();
  };

  const selectClient = (e, client) => {
    setSelectedClient(client);
  };

  const ok = () => {
    selectedClientCallback(selectedClient);
    close();
  };

  const selectedColor = (item) => {
    return selectedClient.id === item.id ? '#F3F3F3' : 'transparent';
  };

  return (
    <div>
      <Modal
        title="Clients"
        visible={open}
        onCancel={closeModal}
        onOk={ok}
      >
        <List
          size="small"
          bordered
          dataSource={records}
          renderItem={
            (item) => (
              <List.Item
                style={{ backgroundColor: selectedColor(item) }}
                onClick={(e) => selectClient(e, item)}
              >
                {`${item.code} - ${item.name}`}
              </List.Item>
            )
          }
        />
      </Modal>
    </div>
  );
};

LoadClientList.propTypes = {
  open: PropTypes.bool.isRequired,
  records: PropTypes.oneOfType([PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
  selectedClientCallback: PropTypes.func.isRequired,
};

export default LoadClientList;
