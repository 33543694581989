import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputElem,
} from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import {
  getCurrency,
  createCurrency,
  updateCurrency,
} from '../../../api/currency';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import PageReload from '../page-reload-message';
import 'antd/dist/antd.css';
import './index.css';

const CurrencyForm = ({ form, match }) => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingCurrency, setExistingCurrency] = useState(null);

  useEffect(() => {
    const getAndSetCurrency = async () => {
      if (match.params.id) {
        const res = await getCurrency(match.params.id);
        setExistingCurrency(res.data);
      }
    };
    getAndSetCurrency();
    // eslint-disable-next-line
  }, [])

  const saveClient = async (values) => {
    try {
      setSubmitting(true);
      const currencyObj = {
        name: values.currencyName,
        code: values.currencyCode,
      };

      if (existingCurrency) {
        const currencyObjWithId = { id: existingCurrency.id, ...currencyObj };
        await updateCurrency(currencyObjWithId);
        Notifcations.success('Currency successfully updated.');
      } else {
        await createCurrency(currencyObj);
        Notifcations.success('Currency successfully created.');
      }

      setSubmitting(false);
      PageReload(() => { goHere(history, RouteRegistry.currency.path); });
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 409) {
        Notifcations.error(error.response.data.message);
      } else {
        Notifcations.error('Something went wrong while managing your currency.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveClient(values);
      }
    });
  };

  const formSubmitButtonText = () => {
    if (existingCurrency) {
      return 'Update';
    }
    return 'Create';
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form onSubmit={handleSubmit} className="currency-form">
          <InputElem
            form={form}
            fieldName="currencyCode"
            rules={[
              {
                required: true,
                message: 'Please enter the currency code',
                len: 3,
                pattern: '^([A-Z]){3}$',
              },
            ]}
            placeholder="Currency Code*"
            type="text"
            initialValue={existingCurrency ? existingCurrency.code : null}
          />
          <InputElem
            form={form}
            fieldName="currencyName"
            rules={[
              { required: true, message: 'Please enter currency name' },
            ]}
            placeholder="Currency Name*"
            type="text"
            initialValue={existingCurrency ? existingCurrency.name : null}
          />
          <Form.Item className="no-bottom-gutter">
            <Button type="primary" htmlType="submit" className="currency-form-button button-right" size="large" loading={isSubmitting}>
              {formSubmitButtonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

CurrencyForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const CurrencyFormWrapper = Form.create({ name: 'currency-form' })(CurrencyForm);

export default CurrencyFormWrapper;
