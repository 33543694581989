import axios from 'axios';
import { get } from '../util/storage';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

axiosInstance
  .interceptors
  .request
  .use((config) => {
    const accessToken = get(process.env.REACT_APP_TOKEN_NAME);
    const updatedConfig = { ...config };
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
    return updatedConfig;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;
