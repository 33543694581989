import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const getCurrencies = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`/currency?${queryString}`);
  return response;
};

const getCurrenciesWithQuotataionCount = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`/currency/with-quotation-count?${queryString}`);
  return response;
};

const getCurrency = async (id) => {
  const response = await axios.get(`/currency/${id}`);
  return response;
};

const createCurrency = async (newCurrency) => {
  const response = await axios.post('/currency', newCurrency);
  return response;
};

const updateCurrency = async (updatedCurrency) => {
  const response = await axios.put('/currency', updatedCurrency);
  return response;
};

const deleteCurrency = async (id) => {
  const response = await axios.delete(`/currency/${id}`);
  return response;
};

export {
  getCurrencies,
  getCurrenciesWithQuotataionCount,
  getCurrency,
  createCurrency,
  updateCurrency,
  deleteCurrency,
};
