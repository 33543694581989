import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../containers/auth';
import RouterRegistry from './registry';

const PrivateRoute = ({ component, location, ...rest }) => {
  const authService = AuthService.useContainer();
  const currenctPath = rest.path;
  const unauthrizedError = {
    status: '403',
    title: '403',
    message: 'Sorry, you are not authorized to access this page.',
  };

  if (!authService.isAuthenticated()) {
    return (
      <Redirect to={{
        pathname: RouterRegistry.login.path,
        state: { from: location },
      }}
      />
    );
  }

  return !authService.isAuthorizedToNavigate(currenctPath)
    ? (
      <Redirect to={{
        pathname: RouterRegistry.error.path,
        state: { error: unauthrizedError },
      }}
      />
    )
    : <Route {...rest} component={component} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
};

PrivateRoute.defaultProps = {
  location: null,
};

export default PrivateRoute;
