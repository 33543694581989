import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RouteRegistry from './registry';
import Login from '../components/login';
import ForgotPassword from '../components/forgot-password';
import PrivateRoute from './private-route';
import DashBoard from '../components/dashboard';
import { QuotationGrid, QuotationForm } from '../components/quotation/index';
import { ClientGrid, ClientForm } from '../components/client';
import InvoiceGrid from '../components/invoice/grid/grid';
import QuotationView from '../components/quotation/pdf-view';
import ActionLog from '../components/action-logs';
import { CurrencyForm, CurrencyGrid } from '../components/currency';
import { UserForm, UserGrid } from '../components/users';
import { BankDetailsForm, BankDetailsGrid } from '../components/bank-details';
import { ProductCategoryForm, ProductCategoryGrid } from '../components/product-category';
import { InvoiceForm, InvoicesList } from '../components/service-bills';
import InvoiceView from '../components/service-bills/pdf-view';
import Error from '../components/errors/error';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={RouteRegistry.login.path} component={Login} />
      <Route exact path={RouteRegistry.forgotPassword.path} component={ForgotPassword} />
      <PrivateRoute exact path={RouteRegistry.dashboard.path} component={DashBoard} />
      <PrivateRoute exact path={RouteRegistry.quotation.path} component={QuotationGrid} />
      <PrivateRoute exact path={RouteRegistry.quotationCreate.path} component={QuotationForm} />
      <PrivateRoute exact path={RouteRegistry.quotationUpdate.path} component={QuotationForm} />
      <PrivateRoute exact path={RouteRegistry.quotationView.path} component={QuotationView} />
      <PrivateRoute exact path={RouteRegistry.client.path} component={ClientGrid} />
      <PrivateRoute exact path={RouteRegistry.clientCreate.path} component={ClientForm} />
      <PrivateRoute exact path={RouteRegistry.clientUpdate.path} component={ClientForm} />
      <PrivateRoute exact path={RouteRegistry.invoice.path} component={InvoiceGrid} />
      <PrivateRoute exact path={RouteRegistry.invoiceList.path} component={InvoicesList} />
      <PrivateRoute exact path={RouteRegistry.invoiceCreate.path} component={InvoiceForm} />
      <PrivateRoute exact path={RouteRegistry.invoiceView.path} component={InvoiceView} />
      <PrivateRoute exact path={RouteRegistry.invoiceUpdate.path} component={InvoiceForm} />
      <PrivateRoute exact path={RouteRegistry.actionLogs.path} component={ActionLog} />
      <PrivateRoute exact path={RouteRegistry.currency.path} component={CurrencyGrid} />
      <PrivateRoute exact path={RouteRegistry.currencyCreate.path} component={CurrencyForm} />
      <PrivateRoute exact path={RouteRegistry.currencyUpdate.path} component={CurrencyForm} />
      <PrivateRoute exact path={RouteRegistry.user.path} component={UserGrid} />
      <PrivateRoute exact path={RouteRegistry.userCreate.path} component={UserForm} />
      <PrivateRoute exact path={RouteRegistry.userUpdate.path} component={UserForm} />
      <PrivateRoute exact path={RouteRegistry.bankDetails.path} component={BankDetailsGrid} />
      <PrivateRoute exact path={RouteRegistry.bankDetailsCreate.path} component={BankDetailsForm} />
      <PrivateRoute exact path={RouteRegistry.bankDetailsUpdate.path} component={BankDetailsForm} />
      <PrivateRoute
        exact
        path={RouteRegistry.productCategory.path}
        component={ProductCategoryGrid}
      />
      <PrivateRoute
        exact
        path={RouteRegistry.productCategoryCreate.path}
        component={ProductCategoryForm}
      />
      <PrivateRoute
        exact
        path={RouteRegistry.productCategoryUpdate.path}
        component={ProductCategoryForm}
      />
      <PrivateRoute exact path={RouteRegistry.error.path} component={Error} />
    </Switch>
  );
};

export default Routes;
