import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  DatePicker as AndDatePicker,
} from 'antd';
import Enums from '../../../constants/enum';

import './index.css';

const DatePicker = ({
  form,
  fieldName,
  placeholder,
  initialValue,
  rules,
  value,
  // onChange,
}) => {
  const { getFieldDecorator } = form;
  return (
    <Form.Item className="custom-form-element">
      <div className="custom-datepicker-holder">
        <label htmlFor={fieldName} className="datepicker-label">{placeholder}</label>
        {getFieldDecorator(fieldName, {
          rules,
          initialValue,
        })(
          <AndDatePicker
            // onChange={onChange}
            value={value}
            format={Enums.Formats.DateFormat}
          />,
        )}
      </div>
    </Form.Item>
  );
};

DatePicker.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.object]),
  rules: PropTypes.oneOfType([PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.object]),
  // onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  rules: [],
  initialValue: null,
  value: null,
  // onChange: {},
};

export default DatePicker;
