import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'antd';
import PropTypes from 'prop-types';
import AuthContainer from '../../containers/auth';
import RouterRegistry from '../../router/registry';
import { InputElem } from '../common/form-elements';
import Notifications from '../common/notifications';
import 'antd/dist/antd.css';
import './index.css';
import fidenzSignia from '../../assets/images/fidenz_logo.svg';

const FrogotPassword = ({ form, location }) => {
  const authService = AuthContainer.useContainer();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (authService.isAuthenticated()) {
      if (location.state) {
        history.push(location.state.from.pathname);
        return;
      }
      history.push(RouterRegistry.dashboard.path);
    }
  }, [authService.authenticated, authService, location, history]);

  const goBackToLogin = () => {
    history.push(RouterRegistry.login.path);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err == null) {
        setIsLoading(true);
        authService.forgetPassword(
          values.email,
          Notifications,
          (success) => {
            if (success) {
              goBackToLogin();
            }
            setIsLoading(false);
          },
        );
      } else {
        setErrorMessage(err.email.errors.message);
      }
    });
  };

  const handleKeyPress = () => {

  };

  return (
    <div className="login-form-container">
      <Form onSubmit={handleSubmit} className="login-form">
        <div className="logo-holder">
          <img
            className="logo-svg"
            src={fidenzSignia}
            alt="Fidenz Technologies"
          />
          <p className="logo-text">Billar</p>
        </div>
        <InputElem
          form={form}
          fieldName="email"
          rules={[
            { required: true, message: 'Please enter the E-mail' },
            { type: 'email', message: 'Enter a valid E-mail' },
          ]}
          placeholder="Email"
          type="text"
          icon="mail"
        />
        <div className="test">
          <p className="login-error">{errorMessage}</p>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
        <span onClick={goBackToLogin} role="button" tabIndex="0" onKeyPress={handleKeyPress} className="login-form-link">Login</span>
      </Form>
    </div>
  );
};

FrogotPassword.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const FrogotPasswordForm = Form.create({ name: 'app_forgot_password' })(FrogotPassword);

export default FrogotPasswordForm;
