import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './router/index';
import AuthService from './containers/auth';
import Layout from './components/layout';

const AppBody = () => {
  const mainBody = () => {
    return <Routes />;
  };

  return (
    <Router>
      <Layout
        body={mainBody}
      />
    </Router>
  );
};

const App = () => {
  return (
    <div className="App">
      <AuthService.Provider>
        <AppBody />
      </AuthService.Provider>
    </div>
  );
};

export default App;
