import React, { } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Redirect } from 'react-router-dom';
import {
  Result,
  Button,
} from 'antd';
import RouterRegistry from '../../router/registry';

const Error = ({ location }) => {
  const error = location.state ? location.state.error : null;
  const history = useHistory();
  return (location && error
    ? (
      <Result
        status={error ? error.status : '500'}
        title={error ? error.title : 'Something Went Wrong'}
        subTitle={error ? error.message : 'Please go back then refresh the page and try again. If you are keep geting this again and again please contact system administrator. Sorry for the inconvenience caused.'}
        extra={<Button type="primary" onClick={() => { history.push(RouterRegistry.dashboard.path); }}>Go To Dashboard</Button>}
      />
    )
    : (
      <Redirect to={{
        pathname: RouterRegistry.login.path,
      }}
      />
    )
  );
};

Error.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

Error.defaultProps = {
  location: null,
};

export default Error;
