import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const subPath = 'product-category';

const getProductCategories = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`${subPath}?${queryString}`);
  console.log(response);
  console.log('response');
  return response;
};

const getProductCategory = async (id) => {
  const response = await axios.get(`${subPath}/${id}`);
  return response;
};

const createProductCategory = async (newProductCategory) => {
  const response = await axios.post(`${subPath}`, newProductCategory);
  return response;
};

const updateProductCategory = async (updatedProductCategory) => {
  const response = await axios.put(`${subPath}`, updatedProductCategory);
  return response;
};

const deleteProductCategory = async (id) => {
  const response = await axios.delete(`${subPath}/${id}`);
  return response;
};

export {
  getProductCategories,
  getProductCategory,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
};
