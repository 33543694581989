import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Icon,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { getClients } from '../../../api/client';
import { getServiceBill, createServiceBill, updateServiceBill } from '../../../api/service-bill';
import {
  InputElem,
  DatePicker,
} from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import InvoiceItem from './invoice-item';
import 'antd/dist/antd.css';
import './index.css';
import LoadClientList from './select-client';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import { getCurrencyFromLocalStorage, getQueryString } from '../../../util/common-util';
import Enums from '../../../constants/enum';

const InvoiceForm = ({ form, match }) => {
  const history = useHistory();
  const location = useLocation();

  const invoiceItem = [{
    key: 'pp-0',
    productCategory: '',
    description: '',
    quantity: 0,
    amount: 0,
    invoiceId: '',
  }];

  const [invoiceItems, setInvoiceItems] = useState([{
    key: 'pp-0',
    productCategory: '',
    description: '',
    quantity: 0,
    amount: 0,
    invoiceId: '',
  }]);
  const [clients, setClients] = useState([]);
  const [openClientModel, setOpenClientModel] = useState(false);
  const [invoicedClient, setInvoicedClient] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [invoiceItemError, setInvoiceItemError] = useState('');
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [serviceBillId, setServiceBillId] = useState();

  useEffect(() => {
    const prepareClients = async () => {
      const response = await getClients();
      const gotClients = response.data;
      setClients(gotClients);
    };
    prepareClients();
    // eslint-disable-next-line
  }, []);

  const settingExistingData = (data, isHardCopy = true) => {
    const formData = {
      invoiceNo: data.invoiceNo,
      poNumber: data.poNumber,
      invoiceDate: !isHardCopy ? invoiceItem : data.invoiceDate,
      dueDate: data.dueDate,
      client: data.client,
    };

    setInvoicedClient(data.client);

    if (isHardCopy) {
      formData.id = data.id;
      formData.code = data.code;
      formData.status = data.status;
    }

    const prepInvoiceItems = data.invoices.map((invoice) => {
      const { id } = invoice;
      const copiedInvoice = {
        key: invoice.id,
        productCategory: invoice.productCategory ? invoice.productCategory.name : '',
        description: invoice.quotation.code,
        amount: parseFloat(invoice.invoicedAmount),
        quantity: 1,
        invoiceId: invoice.id,
      };
      if (isHardCopy) {
        copiedInvoice.id = id;
      }
      return copiedInvoice;
    });
    setInvoiceItems(!isHardCopy || prepInvoiceItems.length < 1 ? invoiceItem : prepInvoiceItems);
    setEditingInvoice(formData);
  };

  useEffect(() => {
    const gettingInvoice = async () => {
      const copyId = getQueryString(location.search, 'copyId');

      if (match.params.id) {
        const res = await getServiceBill(match.params.id);
        settingExistingData(res.data);
        setServiceBillId(match.params.id);
      } else if (copyId) {
        const res = await getServiceBill(copyId);
        settingExistingData(res.data, false);
      }
    };
    gettingInvoice();
    // eslint-disable-next-line
  }, [match]);

  const invoiceItemInvalid = () => {
    const errorList = [];
    let errorMessage = '';
    invoiceItems.forEach((item) => {
      if (item.description.trim() === '' && item.quantity <= 0 && item.amount <= 0) {
        errorList.push(item);
        errorMessage = 'Invalid invoice item quotation number(s), quantity(s) and amount(s) found, ';
      } else if (item.description.trim() === '') {
        errorList.push(item);
        errorMessage = 'Invalid invoice item quotation number(s) found, ';
      // } else if (!item.quantity) {
      //   errorList.push(item);
      //   errorMessage = 'Invalid invoice item quantity(s) found, ';
      } else if (item.amount <= 0) {
        errorList.push(item);
        errorMessage = 'Invalid invoice item amount(s) found, ';
      }
    });

    let valid = true;
    if (errorList.length > 0) {
      valid = false;
    }
    errorMessage = `${errorMessage} Check entered invoice item details.`;
    return {
      valid,
      errorMessage,
    };
  };

  const saveInvoice = async (values, status) => {
    try {
      setInvoiceItemError(null);
      if (status !== Enums.ServiceBillStatus.DRAFT) {
        const invoiceValidationObj = invoiceItemInvalid();
        if (!invoiceValidationObj.valid) {
          setInvoiceItemError(invoiceValidationObj.errorMessage);
          return;
        }
      }

      setSubmitting(true);
      const serviceBillObj = {
        invoiceNo: values.invoiceNumber,
        poNumber: values.poNumber,
        invoiceDate: values.invoiceDate,
        dueDate: values.paymentDue,
        client: editingInvoice ? editingInvoice.client.code : invoicedClient.code,
        status: serviceBillId !== undefined ? Enums.ServiceBillStatus.UNSENT : status,
        invoices: status === Enums.ServiceBillStatus.DRAFT ? [] : invoiceItems,
      };
      if (serviceBillId !== undefined) {
        await updateServiceBill(serviceBillId, serviceBillObj);
        Notifcations.success('Invoice successfully updated.');
      } else {
        await createServiceBill(serviceBillObj);
        Notifcations.success('Invoice successfully created.');
      }

      goHere(history, RouteRegistry.invoiceList.path);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      Notifcations.error('Something went wrong when saving invoice.');
    }
  };

  const handleSubmit = (status) => {
    form.validateFields((err, values) => {
      if (!err) {
        saveInvoice(values, status);
      }
    });
  };

  const getRandomKey = (prefix) => {
    const timestamp = moment().format('x');
    return `${prefix}-${timestamp}`;
  };

  const dateValidator = (rule, value, callback) => {
    try {
      if (!value) {
        throw new Error('Pick an Invoice Date.');
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

  const loadClientList = () => {
    setOpenClientModel(true);
  };

  const closeClientModel = () => {
    setOpenClientModel(false);
  };

  const getClientsList = () => {
    if (!openClientModel) {
      return null;
    }
    return (
      <LoadClientList
        open={openClientModel}
        records={clients}
        close={closeClientModel}
        selectedClientCallback={(client) => setInvoicedClient(client)}
      />
    );
  };

  const formSubmitButtonText = () => {
    if (editingInvoice && editingInvoice.id) {
      return 'Update';
    }
    return 'Save and Continue';
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form className="invoice-create">
          <Row className="button-row">
            <Button
              type="primary"
              htmlType="submit"
              className="invoice-form-button button-right"
              size="large"
              loading={isSubmitting}
              onClick={() => handleSubmit(Enums.ServiceBillStatus.UNSENT)}
            >
              {formSubmitButtonText()}
            </Button>
            {
              !editingInvoice
              && (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="invoice-form-button button-right"
                  size="large"
                  loading={isSubmitting}
                  onClick={() => handleSubmit(Enums.ServiceBillStatus.DRAFT)}
                  disabled={invoiceItems[0].description !== '' && true}
                >
                  Save Draft
                </Button>
              )
            }
          </Row>
          <Row>
            <Col lg={12} span={24}>
              {
              invoicedClient !== '' || editingInvoice
                ? (
                  <div className="invoice-customer details">
                    <div className="details">
                      <div>{`Company: ${editingInvoice ? editingInvoice.client.name : invoicedClient.name}`}</div>
                      <div>{`Contact Person: ${editingInvoice ? editingInvoice.client.contactPersonName : invoicedClient.contactPersonName}`}</div>
                      <div>Address: </div>
                      <div className="client-address">{`${editingInvoice ? editingInvoice.client.addressLineOne : invoicedClient.addressLineOne}`}</div>
                      <div className="client-address">{`${editingInvoice ? editingInvoice.client.addressLineTwo : invoicedClient.addressLineTwo}`}</div>
                      <div className="client-address">{`${editingInvoice ? editingInvoice.client.city : invoicedClient.city}`}</div>
                      <div className="client-address">{`${editingInvoice ? editingInvoice.client.state : invoicedClient.state}`}</div>
                      <div>{`Phone: ${invoicedClient.phone}`}</div>
                    </div>
                    {
                      !editingInvoice && <Icon type="form" onClick={() => loadClientList()} />
                    }
                  </div>
                )
                : (
                  <div className="invoice-customer">
                    <Icon
                      type="user-add"
                      className="customer-icon"
                      onClick={() => loadClientList()}
                    />
                    <button
                      type="button"
                      onClick={() => loadClientList()}
                      className="customer-title"
                    >
                      Add a customer
                    </button>
                  </div>
                )
              }
            </Col>
            <Col lg={12} span={24}>
              <InputElem
                form={form}
                fieldName="invoiceNumber"
                rules={[
                  { required: true, message: 'Please enter a title for the project.' },
                ]}
                placeholder="Invoice number"
                type="text"
                initialValue={editingInvoice ? editingInvoice.invoiceNo : ''}
                disabled={editingInvoice && editingInvoice.id}
              />
              <InputElem
                form={form}
                fieldName="poNumber"
                rules={[
                  { required: true, message: 'Please enter a title for the project.' },
                ]}
                placeholder="P.O/S.O number"
                type="text"
                initialValue={editingInvoice ? editingInvoice.poNumber : ''}
              />
              <div className="invoice-create-item">
                <p className="picker-label">Invoice Date</p>
                <div className="invoice-create-item-control">
                  <DatePicker
                    form={form}
                    fieldName="invoiceDate"
                    initialValue={editingInvoice ? moment(editingInvoice.invoiceDate) : moment().startOf('day')}
                    rules={[
                      {
                        validator: dateValidator,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="invoice-create-item">
                <p className="picker-label">Payment Due</p>
                <div className="invoice-create-item-control">
                  <DatePicker
                    form={form}
                    fieldName="paymentDue"
                    initialValue={editingInvoice ? moment(editingInvoice.dueDate) : moment().startOf('day').add(7, 'days')}
                    rules={[
                      {
                        validator: dateValidator,
                      },
                    ]}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <InvoiceItem
            invoiceItems={invoiceItems}
            setInvoiceItems={setInvoiceItems}
            getRandomKey={getRandomKey}
            invoiceItemError={invoiceItemError}
            clientCode={invoicedClient.code}
          />
        </Form>
        <Divider />
        <div className="note-and-terms">
          <div className="title">Notes / Terms</div>
          <div className="content">{`Amount in ${getCurrencyFromLocalStorage()}`}</div>
          <div className="content">Holder: Fidenz Pvt Ltd. (PV 74438)</div>
          <div className="content">{`Acc No: ${invoicedClient ? invoicedClient.bankDetails.accountNumber : ''}`}</div>
          <div className="content">{`SWIFT Code: ${invoicedClient ? invoicedClient.bankDetails.swiftCode : ''}`}</div>
        </div>
        {getClientsList()}
      </div>
    </div>
  );
};

InvoiceForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const InvoiceFormWrapper = Form.create({ name: 'invoice-create' })(InvoiceForm);

export default InvoiceFormWrapper;
