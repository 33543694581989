import axios from './api-controller';

const createClient = async (clientData) => {
  const response = await axios.post('/clients', clientData);
  return response;
};

const getClients = async () => {
  const response = await axios.get('/clients');
  return response;
};

const getClientsForFilter = async () => {
  const response = await axios.get('/clients/filter');
  return response;
};

const getClient = async (id) => {
  const response = await axios.get(`/clients/${id}`);
  return response;
};

const updateClient = async (id, clientData) => {
  const response = await axios.put(`/clients/${id}`, clientData);
  return response;
};

export {
  createClient,
  getClients,
  getClientsForFilter,
  getClient,
  updateClient,
};
