import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Icon,
  InputNumber,
} from 'antd';
import Enums from '../../../constants/enum';

import './index.css';

const { TextArea } = Input;

const InputElem = ({
  form,
  fieldName,
  rules,
  placeholder,
  type,
  icon,
  area,
  initialValue,
  disabled,
  number,
  onChange,
  autoComplete,
}) => {
  const { getFieldDecorator } = form;

  const getIcon = () => {
    if (icon) {
      return <Icon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />;
    }
    return null;
  };

  const inputPrefix = getIcon();
  const getInput = () => {
    if (area) {
      return (
        <TextArea
          rows={4}
          id={fieldName}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    }
    if (number) {
      return (
        <InputNumber
          precision={Enums.Formats.AmountPrecision}
          prefix={inputPrefix}
          placeholder={placeholder}
          id={fieldName}
          disabled={disabled}
          className="input-number-field"
        />
      );
    }
    return (
      <Input
        prefix={inputPrefix}
        placeholder={placeholder}
        type={type}
        id={fieldName}
        disabled={disabled}
        onChange={onChange}
        autoComplete={!autoComplete ? 'new-password' : 'on'}
      />
    );
  };

  return (
    <Form.Item className="custom-form-element">
      <label htmlFor={fieldName}>{placeholder}</label>
      {getFieldDecorator(fieldName, {
        rules,
        initialValue,
      })(
        getInput(),
      )}
    </Form.Item>
  );
};

InputElem.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fieldName: PropTypes.string.isRequired,
  rules: PropTypes.oneOfType([PropTypes.array]),
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  area: PropTypes.bool,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  number: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  autoComplete: PropTypes.bool,
};

InputElem.defaultProps = {
  rules: [],
  icon: '',
  area: false,
  initialValue: '',
  disabled: false,
  number: false,
  autoComplete: true,
  onChange: () => { },
};

export default InputElem;
