import React, {
  useState,
} from 'react';
import { Icon } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import SidebarCollpesItem from './collapse-item';
import RouteRegistry from '../../../router/registry';
import goHere from '../../../router/go-here';
import AuthService from '../../../containers/auth';
import fidenzLogo from '../../../assets/images/billar_logo.svg';
import './index.css';

const SidebarRoute = ({
  tabIndex,
  path,
  title,
  currentPathname,
  route,
  iconName,
}) => {
  let routeActiveClass = '';
  if (currentPathname === path) {
    routeActiveClass = 'sidebar-route-active';
  }
  return (
    <div className={`sidebar-route ${routeActiveClass}`} role="button" tabIndex={tabIndex} onClick={() => route(path)} onKeyDown={() => route(path)}>
      <Icon type={iconName} className="sidebar-item-icon" />
      {title}
    </div>
  );
};

SidebarRoute.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentPathname: PropTypes.string.isRequired,
  route: PropTypes.func.isRequired,
  iconName: PropTypes.string,
};

SidebarRoute.defaultProps = {
  iconName: '',
};

const getCurrentActivePath = (location) => {
  let activePath = location.pathname;
  const selectedPath = find(RouteRegistry, (regRoute) => {
    if (location.state && location.state.registryPathname) {
      return regRoute.path === location.state.registryPathname;
    }
    return regRoute.path === activePath;
  });
  if (selectedPath && selectedPath.type === 'sub') {
    const parent = RouteRegistry[selectedPath.parent];
    activePath = parent.path;
  }
  return activePath;
};

const Sidebar = () => {
  const history = useHistory();
  const authService = AuthService.useContainer();
  const [collapsed, setCollapsed] = useState(true);

  const toggleSideBar = () => {
    if (collapsed) {
      setCollapsed(false);
      return;
    }
    setCollapsed(true);
  };

  const route = (path) => {
    goHere(history, path);
  };

  const visibilityClass = collapsed ? 'sidebar-collapsed' : 'sidebar-expanded';
  const currentPathname = getCurrentActivePath(history.location);

  if (!authService.isAuthenticated()) {
    return null;
  }

  return (
    <>
      <Icon type="menu" onClick={toggleSideBar} className="sidebar-toggler" />
      <div className={`sidebar-holder ${visibilityClass}`}>
        <div className="sidebar-header">
          <Icon type="close" onClick={toggleSideBar} className="sidebar-close" />
          <img src={fidenzLogo} alt="Fz" className="sidebar-logo" />
        </div>
        <div className="sidebar-items">
          {authService.isAuthorizedToNavigate(RouteRegistry.dashboard.path)
            && (
              <SidebarRoute
                tabIndex={0}
                path={RouteRegistry.dashboard.path}
                title="Dashboard"
                currentPathname={currentPathname}
                route={route}
                iconName="dashboard"
              />
            )}
          {authService.isAuthorizedToNavigate(RouteRegistry.quotation.path)
            && (
              <SidebarRoute
                tabIndex={-1}
                path={RouteRegistry.quotation.path}
                title="Quotations"
                currentPathname={currentPathname}
                route={route}
                iconName="dollar"
              />
            )}
          {authService.isAuthorizedToNavigate(RouteRegistry.invoice.path)
            && (
              <SidebarRoute
                tabIndex={-2}
                path={RouteRegistry.invoice.path}
                title="Invoices"
                currentPathname={currentPathname}
                route={route}
                iconName="diff"
              />
            )}
          {/* {authService.isAuthorizedToNavigate(RouteRegistry.invoiceList.path)
            && (
              <SidebarRoute
                tabIndex={-2}
                path={RouteRegistry.invoiceList.path}
                title="Invoices"
                currentPathname={currentPathname}
                route={route}
                iconName="transaction"
              />
            )} */}
          <SidebarCollpesItem collapseTitle="Master Data" collapsedIcon="database">
            {authService.isAuthorizedToNavigate(RouteRegistry.client.path)
              && (
                <SidebarRoute
                  tabIndex={-3}
                  path={RouteRegistry.client.path}
                  title="Clients"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
            {authService.isAuthorizedToNavigate(RouteRegistry.currency.path)
              && (
                <SidebarRoute
                  tabIndex={-4}
                  path={RouteRegistry.currency.path}
                  title="Currency"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
            {authService.isAuthorizedToNavigate(RouteRegistry.user.path)
              && (
                <SidebarRoute
                  tabIndex={-5}
                  path={RouteRegistry.user.path}
                  title="User"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
            {authService.isAuthorizedToNavigate(RouteRegistry.productCategory.path)
              && (
                <SidebarRoute
                  tabIndex={-6}
                  path={RouteRegistry.productCategory.path}
                  title="Product Category"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
            {authService.isAuthorizedToNavigate(RouteRegistry.bankDetails.path)
              && (
                <SidebarRoute
                  tabIndex={-7}
                  path={RouteRegistry.bankDetails.path}
                  title="Bank Details"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
          </SidebarCollpesItem>
          <SidebarCollpesItem collapseTitle="Administrative" collapsedIcon="crown">
            {authService.isAuthorizedToNavigate(RouteRegistry.actionLogs.path)
              && (
                <SidebarRoute
                  tabIndex={-8}
                  path={RouteRegistry.actionLogs.path}
                  title="Action Logs"
                  currentPathname={currentPathname}
                  route={route}
                />
              )}
          </SidebarCollpesItem>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
