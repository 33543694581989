import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { getUserProfile } from '../api/user-permission';
import auth0 from '../util/auth0/auth0';
import * as Storage from '../util/storage';
import RouterRegistry from '../router/registry';
import Enums from '../constants/enum';

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userPermission, setUserPermission] = useState({});

  const settingUserData = (jwtdata) => {
    const obj = {
      username: jwtdata[`${process.env.REACT_APP_AUTH0_TOKENNAMESPACE}name`],
      userimage: jwtdata[`${process.env.REACT_APP_AUTH0_TOKENNAMESPACE}picture`],
      userid: jwtdata.sub,
    };
    setUserInfo(obj);
  };

  const checkForValidToken = (token) => {
    try {
      const tokenData = jwtDecode(token);
      const expireTimeStamp = tokenData.exp;
      const currentTimeStamp = moment().format('x') / 1000;
      if (expireTimeStamp < currentTimeStamp) {
        Storage.remove(process.env.REACT_APP_TOKEN_NAME);
        Storage.remove(process.env.REACT_APP_CURRENCY_NAME);
        Storage.remove(process.env.REACT_APP_PERMISSION_NAME);
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const logOut = () => {
    setAuthenticated(false);
    setUserInfo({});
    setUserPermission({});
    Storage.remove(process.env.REACT_APP_TOKEN_NAME);
    Storage.remove(process.env.REACT_APP_CURRENCY_NAME);
    Storage.remove(process.env.REACT_APP_PERMISSION_NAME);
  };

  const sessionCheck = async () => {
    const sessionValue = await Storage.get(process.env.REACT_APP_TOKEN_NAME);
    const permissionValue = await Storage.get(process.env.REACT_APP_PERMISSION_NAME);
    if (permissionValue && sessionValue && checkForValidToken(sessionValue)) {
      setAuthenticated(true);
      const jwtdata = await jwtDecode(sessionValue);
      settingUserData(jwtdata);
      setUserPermission(permissionValue);
    }
  };

  useEffect(() => {
    sessionCheck();
  }, []);

  const auth0Login = async (username, password, errorHandler, successHandler) => {
    auth0.client.login({
      realm: 'Username-Password-Authentication',
      username,
      password,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'read:order write:order',
    }, async (err, authResult) => {
      if (err || !authResult) {
        if (!authResult) {
          errorHandler(new Error('Invalid Username or Password'));
          return;
        }
        errorHandler(err);
        return;
      }
      const { accessToken } = authResult;
      if (accessToken) {
        const jwtdata = jwtDecode(accessToken);
        const isVerifiedUser = jwtdata[`${process.env.REACT_APP_AUTH0_TOKENNAMESPACE}email_verified`];

        if (isVerifiedUser) {
          settingUserData(jwtdata);
          Storage.set(process.env.REACT_APP_TOKEN_NAME, accessToken);
          try {
            const res = await getUserProfile();
            const user = res.data;
            setUserPermission(user);
            Storage.set(process.env.REACT_APP_PERMISSION_NAME, user);
            setAuthenticated(true);
            successHandler();
          } catch (error) {
            settingUserData({});
            Storage.remove(process.env.REACT_APP_TOKEN_NAME);
            errorHandler(new Error(`User permission retrieval failed - ${error}`));
          }
        } else {
          errorHandler(new Error('Please verify your email address to login.'));
        }
      } else {
        errorHandler(new Error('Something went wrong. The authentication token is missing'));
      }
    });
  };

  const isAuthenticated = () => {
    return authenticated;
  };

  const getUserCurrentLocation = () => currentLocation;

  const setUserCurrentLocation = (pathname) => {
    setCurrentLocation(pathname);
  };

  const getUserInfo = () => {
    return userInfo;
  };

  const forgetPassword = (email, notification, callback) => {
    if (email) {
      auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, resp) => {
          if (err) {
            notification.error(err.message);
            callback(false);
          } else {
            notification.success(resp);
            callback(true);
          }
        },
      );
    } else {
      notification.error('Username Not provided');
      callback(false);
    }
  };

  const auth0SignUp = async (user, callback) => {
    auth0.signup(
      {
        connection: 'Username-Password-Authentication',
        email: user.email,
        password: user.password,
      },
      (err, res) => {
        callback(err, res);
      },
    );
  };

  const getUserPermissions = () => {
    if (userPermission.email) {
      return userPermission;
    }
    return Storage.get(process.env.REACT_APP_PERMISSION_NAME);
  };

  const getSectionPermissions = (sectionName, permissionType) => {
    const permissionValue = getUserPermissions();
    const sectionPermissions = permissionValue.sectionPermission.find(
      (sec) => {
        return sec.section === sectionName;
      },
    );
    return sectionPermissions ? sectionPermissions[permissionType] : false;
  };

  const isAuthorizedToNavigate = (path) => {
    const permissionValue = getUserPermissions();
    const { role } = permissionValue;

    if (role === Enums.Roles.ADMIN) { return true; }

    let canCreate = false;
    let canRead = false;
    let canUpdate = false;
    // let DELETE = false;

    switch (path) {
      case RouterRegistry.quotation.path:
        canRead = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.READ);
        return canRead;
      case RouterRegistry.quotationCreate.path:
        canRead = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.READ);
        canCreate = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.CREATE);
        return canRead && canCreate;
      case RouterRegistry.quotationUpdate.path:
        canRead = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.READ);
        canUpdate = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.UPDATE);
        return canRead && canUpdate;
      case RouterRegistry.quotationView.path:
        canRead = getSectionPermissions(Enums.Sections.QUOTATIONS, Enums.Access.READ);
        return canRead;
      case RouterRegistry.invoice.path:
        canRead = getSectionPermissions(Enums.Sections.INVOICES, Enums.Access.READ);
        return canRead;
      case RouterRegistry.invoiceList.path:
        canRead = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.READ);
        return canRead;
      case RouterRegistry.invoiceCreate.path:
        canRead = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.READ);
        canCreate = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.CREATE);
        return canRead && canCreate;
      case RouterRegistry.invoiceUpdate.path:
        canRead = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.READ);
        canUpdate = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.UPDATE);
        return canRead && canUpdate;
      case RouterRegistry.invoiceView.path:
        canRead = getSectionPermissions(Enums.Sections.SERVICEBILLS, Enums.Access.READ);
        return canRead;
      // Admin pervillages
      case RouterRegistry.client.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.clientCreate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.clientUpdate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.actionLogs.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.currency.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.currencyCreate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.currencyUpdate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.user.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.userCreate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.userUpdate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.productCategory.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.productCategoryCreate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.productCategoryUpdate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.bankDetails.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.bankDetailsCreate.path:
        return role === Enums.Roles.ADMIN;
      case RouterRegistry.bankDetailsUpdate.path:
        return role === Enums.Roles.ADMIN;
      // default all access
      case RouterRegistry.error.path:
        return true;
      case RouterRegistry.login.path:
        return true;
      case RouterRegistry.forgotPassword.path:
        return true;
      case RouterRegistry.dashboard.path:
        return true;
      default:
        return false;
    }
  };

  const canDo = (sectionName, permissionType) => {
    const permissionValue = getUserPermissions();
    const { role } = permissionValue;

    if (role === Enums.Roles.ADMIN) { return true; }

    let canCreate = false;
    let canRead = false;
    let canUpdate = false;
    let canDelete = false;

    switch (permissionType) {
      case Enums.Access.CREATE:
        canRead = getSectionPermissions(sectionName, Enums.Access.READ);
        canCreate = getSectionPermissions(sectionName, permissionType);
        return canRead && canCreate;
      case Enums.Access.READ:
        canRead = getSectionPermissions(sectionName, permissionType);
        return canRead;
      case Enums.Access.UPDATE:
        canRead = getSectionPermissions(sectionName, Enums.Access.READ);
        canUpdate = getSectionPermissions(sectionName, permissionType);
        return canRead && canUpdate;
      case Enums.Access.DELETE:
        canRead = getSectionPermissions(sectionName, Enums.Access.READ);
        canDelete = getSectionPermissions(sectionName, permissionType);
        return canRead && canDelete;
      default:
        return false;
    }
  };

  return {
    authenticated,
    isAuthenticated,
    auth0Login,
    logOut,
    getUserCurrentLocation,
    setUserCurrentLocation,
    getUserInfo,
    forgetPassword,
    auth0SignUp,
    isAuthorizedToNavigate,
    getUserPermissions,
    canDo,
  };
};

export default createContainer(useAuth);
