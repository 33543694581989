import React from 'react';
import {
  Divider,
  Input,
  DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import moment from 'moment';

import { InputList } from '../../common/form-elements';

import './index.css';

import Enums from '../../../constants/enum';

const ReleasePlanSet = ({
  releasePlans,
  SetReleasePlans,
  releasePlanError,
  setReleasePlanError,
}) => {
  const addReleasePlan = (key) => {
    const items = [...releasePlans];
    const newItem = {
      key,
      description: '',
      releaseDate: moment().format(Enums.Formats.DateFormat),
    };
    items.push(newItem);
    SetReleasePlans(items);
  };

  const handleChange = (event, identifier) => {
    const { name, value } = event.target;
    const releaseItems = [...releasePlans];
    const indexOf = findIndex(releaseItems, (i) => i.key === identifier);
    releaseItems[indexOf] = { ...releaseItems[indexOf], [name]: value };
    SetReleasePlans(releaseItems);
  };

  const handleDateChange = (dateString, identifier) => {
    const releaseItems = [...releasePlans];
    setReleasePlanError(null);
    // releaseItems.forEach((releaseItem) => {
    //   const dateStampOfPrev = moment(releaseItem.releaseDate).format('x');
    //   const dateStampOfCurrent = moment(dateString).format('x');
    //   if (dateStampOfCurrent < dateStampOfPrev) {
    //     setReleasePlanError('Each release date should be ahead of the previous release date.');
    //   }
    // });
    const indexOf = findIndex(releaseItems, (i) => i.key === identifier);
    releaseItems[indexOf] = { ...releaseItems[indexOf], releaseDate: dateString };
    SetReleasePlans(releaseItems);
  };

  const removeItem = (identifier) => {
    const releaseItems = [...releasePlans];
    const indexOf = findIndex(releaseItems, (i) => i.key === identifier);
    releaseItems.splice(indexOf, 1);
    SetReleasePlans(releaseItems);
  };

  const ErrorStatus = () => {
    if (releasePlanError) {
      return <p className="repeatable-form-error">{releasePlanError}</p>;
    }
    return null;
  };

  return (
    <div className="repeatable-form-container">
      <Divider />
      <p className="repeatable-form-title">Release Plan</p>
      <InputList
        items={
          releasePlans.map((item) => ({
            key: item.key,
            body: (
              <>
                <Input
                  type="text"
                  name="description"
                  value={item.description}
                  placeholder="Description"
                  onChange={(e) => { handleChange(e, item.key); }}
                  className="rel-description"
                />
                <DatePicker
                  value={moment(item.releaseDate)}
                  onChange={(dateString) => { handleDateChange(dateString, item.key); }}
                  format={Enums.Formats.DateFormat}
                  className="rel-datepicker"
                />
              </>
            ),
          }))
        }
        addItem={addReleasePlan}
        removeItem={removeItem}
      />
      <ErrorStatus />
    </div>
  );
};

ReleasePlanSet.propTypes = {
  releasePlans: PropTypes.func.isRequired,
  SetReleasePlans: PropTypes.func.isRequired,
  releasePlanError: PropTypes.string.isRequired,
  setReleasePlanError: PropTypes.func.isRequired,
};

export default ReleasePlanSet;
