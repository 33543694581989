import React from 'react';
import {
  Divider,
  Input,
  InputNumber,
} from 'antd';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import { InputList } from '../../common/form-elements';
import Enums from '../../../constants/enum';
import { thousandSeparator, getSum } from '../../../util/data/converter';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import './index.css';

const FinancialSet = ({
  financialItems,
  SetFinancialItems,
  financialError,
}) => {
  const addFItem = (key) => {
    const items = [...financialItems];
    const newItem = {
      key,
      description: '',
      amount: '',
    };
    items.push(newItem);
    SetFinancialItems(items);
  };

  const handleChange = (event, identifier) => {
    const { name, value } = event.target;
    const processedValue = value;
    const finItems = [...financialItems];
    const indexOf = findIndex(finItems, (i) => i.key === identifier);
    finItems[indexOf] = { ...finItems[indexOf], [name]: processedValue };
    SetFinancialItems(finItems);
  };

  const handleNumberChange = (value, name, identifier) => {
    const finItems = [...financialItems];
    const indexOf = findIndex(finItems, (i) => i.key === identifier);
    finItems[indexOf] = { ...finItems[indexOf], [name]: value };
    SetFinancialItems(finItems);
  };

  const removeItem = (identifier) => {
    const finItems = [...financialItems];
    const indexOf = findIndex(finItems, (i) => i.key === identifier);
    finItems.splice(indexOf, 1);
    SetFinancialItems(finItems);
  };

  const ErrorStatus = () => {
    if (financialError) {
      return <p className="repeatable-form-error">{financialError}</p>;
    }
    return null;
  };

  const ShowTotal = () => {
    const totalValue = getSum(financialItems.map((item) => item.amount));

    return (
      <div className="repeatable-total">
        {thousandSeparator(totalValue)}
      </div>
    );
  };

  return (
    <div className="repeatable-form-container">
      <Divider />
      <p className="repeatable-form-title">Financials</p>
      <InputList
        items={
          financialItems.map((item) => ({
            key: item.key,
            body: (
              <>
                <Input
                  type="text"
                  name="description"
                  value={item.description}
                  placeholder="Description"
                  onChange={(e) => { handleChange(e, item.key); }}
                  className="fin-description"
                />
                <InputNumber
                  precision={Enums.Formats.AmountPrecision}
                  name="amount"
                  value={item.amount}
                  placeholder={`Amount (${getCurrencyFromLocalStorage()})`}
                  onChange={(value) => { handleNumberChange(value, 'amount', item.key); }}
                  className="fin-amount"
                />
              </>
            ),
          }))
        }
        addItem={addFItem}
        removeItem={removeItem}
      />
      <ErrorStatus />
      <ShowTotal />
    </div>
  );
};

FinancialSet.propTypes = {
  financialItems: PropTypes.func.isRequired,
  SetFinancialItems: PropTypes.func.isRequired,
  financialError: PropTypes.string.isRequired,
};

export default FinancialSet;
