import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Select,
  Tooltip,
  Icon,
} from 'antd';

import './index.css';

const { Option } = Select;

const renderOptions = (optionSet, textValueSplit) => {
  return optionSet.map((item, index) => {
    const textValue = textValueSplit ? item.text.replace(/([A-Z])/g, ' $1') : item.text;
    const key = `option-${index}`;
    return (
      <Option value={item.key} key={key}>
        {textValue}
      </Option>
    );
  });
};

const SelectBox = ({
  form,
  fieldName,
  placeholder,
  options,
  initialValue,
  rules,
  onChange,
  selectBoxClass,
  textValueSplit,
  info,
  disabled,
}) => {
  const { getFieldDecorator } = form;

  const showTip = () => {
    if (info) {
      return (
        <Tooltip title={info} className="tooltip-breakline" placement="bottom">
          <Icon type="info-circle" className="info-icon" />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Form.Item className="custom-form-element">
      <label htmlFor={fieldName}>{placeholder}</label>
      {showTip()}
      {getFieldDecorator(fieldName, {
        rules,
        initialValue,
      })(
        <Select
          showSearch
          onChange={onChange}
          dropdownClassName={selectBoxClass}
          disabled={disabled}
        >
          {
            renderOptions(options, textValueSplit)
          }
        </Select>,
      )}
    </Form.Item>
  );
};

SelectBox.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  initialValue: PropTypes.string,
  rules: PropTypes.oneOfType([PropTypes.array]),
  onChange: PropTypes.func,
  selectBoxClass: PropTypes.string,
  textValueSplit: PropTypes.bool,
  info: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectBox.defaultProps = {
  rules: [],
  initialValue: '',
  onChange: () => { },
  selectBoxClass: '',
  textValueSplit: false,
  placeholder: '',
  info: '',
  disabled: false,
};

export default SelectBox;
