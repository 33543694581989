import React, {
  useEffect,
  useState,
} from 'react';
import {
  Menu,
  Modal,
  Dropdown,
  Button,
  Icon,
  Radio,
  Alert,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { getCurrencies } from '../../../api/currency';
import Notifications from '../../common/notifications';
import './index.css';

const CurrencyMenu = ({ setCurrency, currency }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCurrencyList = async () => {
    setIsLoading(true);
    try {
      const res = await getCurrencies({});
      const { data } = res;
      if (data) {
        setCurrencyList(data);
      }
      setIsLoading(false);
    } catch (error) {
      Notifications.error('Something went wrong while trying to load currencies.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrencyList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currency) {
      setSelectedCurrency(currency);
    }
    // eslint-disable-next-line
  }, [currency]);

  const getCurrencyFromCurrencyList = (id) => {
    return currencyList.find(
      (c) => {
        return c.id === parseInt(id, 10);
      },
    );
  };

  const selectCurrency = (radioItem) => {
    const { target } = radioItem;
    setCurrency(getCurrencyFromCurrencyList(target.value));
  };

  const switchCurrency = ({ item }) => {
    setCurrency(getCurrencyFromCurrencyList(item.props.eventKey));
  };

  const MenuItem = () => {
    return (
      <Menu onClick={switchCurrency}>
        {
          currencyList.map((item) => {
            return (
              <Menu.Item key={item.id} className={selectedCurrency && item.id === selectedCurrency.id ? 'active-currency' : ''}>
                {item.code}
              </Menu.Item>
            );
          })
        }
      </Menu>
    );
  };

  const RadioItem = () => {
    return (
      currencyList.length > 0
        ? (
          <Radio.Group onChange={selectCurrency} value={-1}>
            {
              currencyList.map((item) => {
                return (
                  <Radio className="radio-item" value={item.id} key={item.id}>
                    {item.code}
                  </Radio>
                );
              })
            }
          </Radio.Group>
        )
        : (
          <Alert
            message="Currencies Not Found"
            description="Unable to find any currencies. Without an active currency, data loading is not possible. Please contact system Admin. Sorry for the inconvenience."
            type="error"
          />
        )
    );
  };

  return (
    selectedCurrency
      ? (
        <Dropdown overlay={MenuItem} trigger={['click']}>
          <Button>
            <span className="trigger-text">{selectedCurrency ? selectedCurrency.code : '---'}</span>
            <Icon type="caret-down" className="trigger-icon" />
          </Button>
        </Dropdown>
      )
      : (
        <Modal
          title="Select Currency"
          visible={selectedCurrency === false}
          closable={false}
          width="250px"
          style={{ textAlign: 'center' }}
          footer={
            !isLoading && currencyList.length > 0
              ? [
                <Alert
                  key={0}
                  message="Please select a currency to load the data"
                  type="info"
                />,
              ]
              : []
          }
        >
          {isLoading ? <Spin size="large" /> : <RadioItem />}
        </Modal>
      )
  );
};

CurrencyMenu.propTypes = {
  setCurrency: PropTypes.func.isRequired,
  currency: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

CurrencyMenu.defaultProps = {
  currency: false,
};

export default CurrencyMenu;
