import axios from './api-controller';

const createServiceBill = async (serviceBill) => {
  const response = await axios.post('/service-bill', serviceBill);
  return response;
};

const queryServiceBills = async (filterOptions) => {
  const response = await axios.post('/service-bill/invoice/query', filterOptions);
  return response;
};

const deleteServiceBill = async (id) => {
  const response = await axios.delete(`/service-bill/${id}`);
  return response;
};

const getServiceBillStatuses = async () => {
  const response = await axios.get('/statuses/serviceBill');
  return response;
};

const getAllServiceBills = async () => {
  const response = await axios.get('/service-bill');
  return response;
};

const getServiceBill = async (id) => {
  const response = await axios.get(`/service-bill/${id}`);
  return response;
};

const updateServiceBill = async (id, serviceBillObj) => {
  const response = await axios.patch(`/service-bill/${id}`, serviceBillObj);
  return response;
};

const updateServiceBillStatus = async (id, serviceBillObj) => {
  const response = await axios.put(`/service-bill/statuschange/${id}`, serviceBillObj);
  return response;
};

const getServiceBillsForFilter = async () => {
  const response = await axios.get('/service-bill/invoice/filter');
  return response;
};

const payServiceBill = async (id, serviceBillObj) => {
  const response = await axios.put(`/service-bill/pay/${id}`, serviceBillObj);
  return response;
};

const getServiceBillStats = async () => {
  const response = await axios.get('/service-bill/invoice/stats');
  return response;
};

export {
  createServiceBill,
  queryServiceBills,
  deleteServiceBill,
  getServiceBillStatuses,
  getAllServiceBills,
  getServiceBill,
  updateServiceBill,
  updateServiceBillStatus,
  getServiceBillsForFilter,
  payServiceBill,
  getServiceBillStats,
};
