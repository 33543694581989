import React, { useState, useEffect } from 'react';
import { Button, Icon, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import { getClients } from '../../../api/client';
import './grid.css';

const ClientGrid = () => {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const createClient = () => {
    goHere(history, RouteRegistry.clientCreate.path);
  };

  useEffect(() => {
    const gettingClients = async () => {
      try {
        setTableDataLoading(true);
        const res = await getClients();
        setClients(res.data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load clients!');
      }
    };
    gettingClients();
  }, []);

  const editClient = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.client.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.clientUpdate.path,
      },
    });
  };

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }, {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  }, {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  }, {
    title: 'Action',
    key: 'action',
    render: (record) => {
      return (
        <div className="grid-action-row">
          <Icon type="form" className="grid-action-icon" onClick={() => { editClient(record.id); }} />
        </div>
      );
    },
    align: 'center',
  }];

  return (
    <div className="content-container">
      <div className="create-client-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={createClient}>
          Create New Client
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={clients}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default ClientGrid;
