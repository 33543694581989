const RouteRegistry = {
  login: {
    path: '/Login',
    title: 'Login',
  },
  forgotPassword: {
    path: '/forgot-password',
    title: 'Forgot Password',
  },
  dashboard: {
    path: '/',
    title: 'Dashboard',
  },
  quotation: {
    path: '/quotations',
    title: 'Quotations',
  },
  quotationCreate: {
    path: '/quotations/create',
    title: 'Quotation Create',
    type: 'sub',
    parent: 'quotation',
    ownTitle: 'New',
  },
  quotationUpdate: {
    path: '/quotations/edit/:id',
    title: 'Quotation Update',
    type: 'sub',
    parent: 'quotation',
    ownTitle: 'Edit',
  },
  quotationView: {
    path: '/quotations/view/:id',
    title: 'Quotation View',
    type: 'sub',
    parent: 'quotation',
    ownTitle: 'View',
  },
  invoice: {
    path: '/invoices',
    title: 'Quotation List',
  },
  client: {
    path: '/clients',
    title: 'Clients',
  },
  clientCreate: {
    path: '/clients/create',
    title: 'Client Create',
    type: 'sub',
    parent: 'client',
    ownTitle: 'New',
  },
  clientUpdate: {
    path: '/clients/edit/:id',
    title: 'Client Update',
    type: 'sub',
    parent: 'client',
    ownTitle: 'Edit',
  },
  actionLogs: {
    path: '/logs',
    title: 'Action Logs',
  },
  currency: {
    path: '/currency',
    title: 'Currency',
  },
  currencyCreate: {
    path: '/currency/create',
    title: 'Currency Add',
    type: 'sub',
    parent: 'currency',
    ownTitle: 'New',
  },
  currencyUpdate: {
    path: '/currency/edit/:id',
    title: 'Currency Edit',
    type: 'sub',
    parent: 'currency',
    ownTitle: 'Edit',
  },
  user: {
    path: '/user',
    title: 'User',
  },
  userCreate: {
    path: '/user/create',
    title: 'User Add',
    type: 'sub',
    parent: 'user',
    ownTitle: 'New',
  },
  userUpdate: {
    path: '/user/edit/:id',
    title: 'User Edit',
    type: 'sub',
    parent: 'user',
    ownTitle: 'Edit',
  },
  productCategory: {
    path: '/product-category',
    title: 'Product Category',
  },
  productCategoryCreate: {
    path: '/product-category/create',
    title: 'Product Category Create',
    type: 'sub',
    parent: 'productCategory',
    ownTitle: 'New',
  },
  productCategoryUpdate: {
    path: '/product-category/edit/:id',
    title: 'Product Category Edit',
    type: 'sub',
    parent: 'productCategory',
    ownTitle: 'Edit',
  },
  bankDetails: {
    path: '/bank-details',
    title: 'Bank Details',
  },
  bankDetailsCreate: {
    path: '/bank-details/create',
    title: 'Bank Details Add',
    type: 'sub',
    parent: 'bankDetails',
    ownTitle: 'New',
  },
  bankDetailsUpdate: {
    path: '/bank-details/edit/:id',
    title: 'Bank Details Edit',
    type: 'sub',
    parent: 'bankDetails',
    ownTitle: 'Edit',
  },
  invoiceList: {
    path: '/invoice-list',
    title: 'Invoices',
  },
  invoiceCreate: {
    path: '/invoice/create',
    title: 'New Invoice',
    type: 'sub',
    parent: 'invoiceList',
    ownTitle: 'New',
  },
  invoiceUpdate: {
    path: '/invoices/edit/:id',
    title: 'Invoice Update',
    type: 'sub',
    parent: 'invoiceList',
    ownTitle: 'Edit',
  },
  invoiceView: {
    path: '/invoices/view/:id',
    title: 'Invoice View',
    type: 'sub',
    parent: 'invoiceList',
    ownTitle: 'View',
  },
  error: {
    path: '/error',
    title: '',
  },
};

export default RouteRegistry;
