import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const createQuotation = async (quotationData) => {
  const response = await axios.post('/quotations', quotationData);
  return response;
};

const getQuotationsForFilter = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`/quotations/filter?${queryString}`);
  return response;
};

const queryQuotation = async (filterOptions) => {
  const response = await axios.post('/quotations/query', filterOptions);
  return response;
};

const getQuotation = async (id) => {
  const response = await axios.get(`/quotations/${id}`);
  return response;
};

const updateQuotation = async (id, quotationData) => {
  const response = await axios.put(`/quotations/${id}`, quotationData);
  return response;
};

const getQuotationStatuses = async () => {
  const response = await axios.get('/statuses/quotation');
  return response;
};

const changeQuotationStatus = async (id, status) => {
  const response = await axios.put(`/quotations/statuschange/${id}`, { status });
  return response;
};

const deleteQuotation = async (id) => {
  const response = await axios.delete(`/quotations/${id}`);
  return response;
};

export {
  createQuotation,
  getQuotationsForFilter,
  getQuotation,
  updateQuotation,
  getQuotationStatuses,
  changeQuotationStatus,
  deleteQuotation,
  queryQuotation,
};
