import React from 'react';
import {
  View,
  Page,
  Document,
  Image,
  Text,
} from '@react-pdf/renderer';
import moment from 'moment';
import Enums from '../../../constants/enum';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import styles from './style';
import fidenzLogo from '../../../assets/images/full-logo.png';

export default (quotation) => {
  const getHeaderSection = () => {
    const client = quotation ? quotation.client : {};
    const quotationCode = quotation ? quotation.code : '';
    const createdDate = quotation ? moment(quotation.createdAt).format(Enums.Formats.DateLongFormat) : '';
    const updatedDate = quotation ? moment(quotation.updatedAt).format(Enums.Formats.DateLongFormat) : '';
    return (
      <>
        <View style={styles.headerSection}>
          <View style={styles.headerRow}>
            <View style={styles.headerLogoLeft}>
              <Image src={fidenzLogo} />
            </View>
            <View style={[styles.headerTopicRight]}>
              <Text style={styles.topHeaderRightTitle}>Quotation</Text>
              <Text style={styles.topHeaderRightTileSub}>{`Fidenz-${getCurrencyFromLocalStorage()}`}</Text>
            </View>
          </View>
          <View style={styles.headerRow}>
            <View style={[styles.leftAlign]}>
              <Text style={styles.headerAddressHeader}>Customer</Text>
              <Text style={styles.headerAddressField}>{client.name}</Text>
              <Text style={styles.headerAddressField}>{`${client.addressLineOne},`}</Text>
              {(client.addressLineTwo) ? <Text style={styles.headerAddressField}>{`${client.addressLineTwo},`}</Text> : null}
              <Text style={styles.headerAddressField}>{`${client.city}, ${client.zipCode}, ${client.country}.`}</Text>
              {(client.contactPersonName) ? <Text style={styles.headerAddressField}>{`${client.contactPersonName}`}</Text> : null}
              {client.phone ? <Text style={styles.headerAddressField}>{`${client.phone}`}</Text> : null}
              <Text style={styles.headerAddressField}>{`${client.email}`}</Text>
            </View>
            <View style={[styles.rightAlign]}>
              <Text style={styles.headerAddressHeader}>From</Text>
              <Text style={styles.headerAddressField}>Fidenz Private Limited</Text>
              <Text style={styles.headerAddressField}>No: 239, Nawala Road,</Text>
              <Text style={styles.headerAddressField}>Nawala, 10120, Sri Lanka.</Text>
              <Text style={styles.headerAddressField}>info@fidenz.com</Text>
            </View>
          </View>
          <View style={styles.quotationMetaHolder}>
            <Text style={styles.headerDateField}>
              {`Quotation No: ${quotationCode}`}
            </Text>
            <Text style={styles.headerDateField}>
              {`Created Date: ${createdDate}`}
            </Text>
            <Text style={styles.headerDateField}>
              {`Updated Date: ${updatedDate}`}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const getTitle = () => {
    const projectTitle = quotation ? quotation.title : '';
    const projectScope = quotation ? quotation.scope : '';
    return (
      <View style={styles.titleSection} wrap={false}>
        <Text style={styles.mainTitle}>
          {projectTitle}
        </Text>
        <View style={styles.divider} />
        <Text style={styles.mainTitleSub}>Scope of Work</Text>
        <Text style={styles.mainTitleSubDesc}>{projectScope}</Text>
      </View>
    );
  };

  const thousandsSeparators = (num) => {
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  };

  const getTwoColGridRow = (
    numberColVal,
    firstColVal,
    secondColVal,
    isHeader,
    isTotalRow,
    colStyleOvrr = {},
  ) => {
    const colTextStyle = isHeader ? styles.colHeader : styles.colData;
    const totalCol = isTotalRow ? styles.totalCol : {};
    const finalColTextStyle = isHeader ? styles.textCenter : styles.textRight;
    const firstColTextStyle = isHeader ? styles.textCenter : {};
    const totalRowTextStyle = isTotalRow ? styles.totalColText : {};
    return (
      <View style={[styles.customGridRow]}>
        <View style={[
          styles.customGridCol,
          styles.numberCol,
          styles.textCenter,
          totalCol,
          colStyleOvrr.firstCol,
        ]}
        >
          <Text style={colTextStyle}>{numberColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.textCol,
          totalCol,
          colStyleOvrr.secondCol,
          firstColTextStyle,
        ]}
        >
          <Text style={[colTextStyle, totalRowTextStyle]}>{firstColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          styles.finalCol,
          finalColTextStyle,
          totalCol,
          colStyleOvrr.thridCol,
        ]}
        >
          <Text style={[colTextStyle, totalRowTextStyle]}>{secondColVal}</Text>
        </View>
      </View>
    );
  };

  const getThreeColGridRow = (
    numberColVal,
    firstColVal,
    secondColVal,
    thirdColVal,
    isHeader,
    isTotalRow,
    colStyleOvrr = {},
  ) => {
    const colTextStyle = isHeader ? styles.colHeader : styles.colData;
    const totalCol = isTotalRow ? styles.totalCol : {};
    const finalColTextStyle = isHeader ? styles.textCenter : styles.textRight;
    const firstColTextStyle = isHeader ? styles.textCenter : {};
    return (
      <View style={[styles.customGridRow]}>
        <View style={[
          styles.customGridCol,
          styles.numberCol,
          styles.textCenter,
          totalCol,
          colStyleOvrr.firstCol,
        ]}
        >
          <Text style={colTextStyle}>{numberColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.midTextCol,
          totalCol,
          colStyleOvrr.secondCol,
          firstColTextStyle,
        ]}
        >
          <Text style={colTextStyle}>{firstColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          totalCol,
          styles.textCenter,
          colStyleOvrr.secondCol,
        ]}
        >
          <Text style={colTextStyle}>{secondColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          styles.finalCol,
          finalColTextStyle,
          totalCol,
          colStyleOvrr.thridCol,
        ]}
        >
          <Text style={colTextStyle}>{thirdColVal}</Text>
        </View>
      </View>
    );
  };

  const getFinancialRows = () => {
    const financials = quotation ? quotation.financials : [];
    let total = 0;
    const rows = financials.map((financial, index) => {
      total += parseFloat(financial.amount);
      const indexVal = (index < 10) ? `0${index + 1}` : index + 1;
      const financialAmount = parseFloat(financial.amount).toFixed(Enums.Formats.AmountPrecision);
      return getTwoColGridRow(
        indexVal,
        financial.description,
        thousandsSeparators(financialAmount),
      );
    });
    const formattedTotal = total.toFixed(Enums.Formats.AmountPrecision);
    const totalRow = getTwoColGridRow('', 'Total', thousandsSeparators(formattedTotal), false, true);
    return [...rows, totalRow];
  };

  const getFinancials = () => {
    return (
      <View style={styles.gridSection} wrap={false}>
        <Text style={styles.subTitle}>Financials</Text>
        <View style={styles.gridTitle}>
          <Text>Financial Details</Text>
        </View>
        {getTwoColGridRow('No', 'Description', `Amount (${getCurrencyFromLocalStorage()})`, true)}
        {getFinancialRows()}
      </View>
    );
  };

  const getReleasePlanRows = () => {
    const releasePlans = quotation ? quotation.releasePlans : [];
    return releasePlans.map((releasePlan, index) => {
      const indexVal = (index < 10) ? `0${index + 1}` : index + 1;
      const releaseDate = moment(releasePlan.releaseDate).format(Enums.Formats.EnglishDateFormat);
      return getTwoColGridRow(
        indexVal,
        releasePlan.description,
        releaseDate,
        false,
        false,
        { thridCol: styles.textCenter },
      );
    });
  };

  const getReleasePlans = () => {
    return (
      <View style={styles.gridSection} wrap={false}>
        <Text style={styles.subTitle}>Delivery</Text>
        <View style={styles.gridTitle}>
          <Text>Delivery Plan</Text>
        </View>
        {getTwoColGridRow('No', 'Description', 'Release Date', true)}
        {getReleasePlanRows()}
      </View>
    );
  };

  const getInvoiceRows = () => {
    const invoices = quotation ? quotation.invoices : [];
    return invoices.map((invoice, index) => {
      const indexVal = (index < 10) ? `0${index + 1}` : index + 1;
      const invoiceDate = moment(invoice.invoiceDate).format(Enums.Formats.EnglishDateFormat);
      const paymentPlanAmount = parseFloat(invoice.invoiceAmount)
        .toFixed(Enums.Formats.AmountPrecision);
      return getThreeColGridRow(
        indexVal,
        invoice.description,
        invoiceDate,
        thousandsSeparators(paymentPlanAmount),
      );
    });
  };

  const getPaymentPlans = () => {
    return (
      <View style={styles.gridSection} wrap={false}>
        <Text style={styles.subTitle}>Payment</Text>
        <View style={styles.gridTitle}>
          <Text>Invoicing Plan</Text>
        </View>
        {getThreeColGridRow('No', 'Description', 'Invoice Date', `Amount (${getCurrencyFromLocalStorage()})`, true)}
        {getInvoiceRows()}
      </View>
    );
  };

  const getFinalSection = () => {
    const terms = quotation ? quotation.terms : '';
    return (
      <View style={styles.finalSection} wrap={false}>
        <Text style={styles.termsSectionTitle}>
          Terms:
        </Text>
        <Text style={styles.termsSectionTerms}>{terms}</Text>
      </View>
    );
  };

  const quotationCode = quotation ? quotation.code : '';
  const renderPDF = () => {
    return (
      <Document title={quotationCode}>
        <Page size="A4" style={styles.page} wrap>
          {getHeaderSection()}
          {getTitle()}
          {getFinancials()}
          {(quotation && quotation.releasePlans.length > 0) ? getReleasePlans() : null}
          {getPaymentPlans()}
          {getFinalSection()}
        </Page>
      </Document>
    );
  };

  return renderPDF();
};
