import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Divider,
  Form,
  Button,
  DatePicker,
} from 'antd';
import PropTypes from 'prop-types';

import Table from '../common/table';
import Notifications from '../common/notifications';

import Enums from '../../constants/enum';

import { getLogs } from '../../api/action-log';

import './index.css';

const FilterForm = ({
  form,
  filterObj,
  applyFilter,
  setFilterObj,
}) => {
  const [filterDateError, setFilterDateError] = useState(null);

  const validateFilterDates = () => {
    setFilterDateError(null);
    if (filterObj.FromDate || filterObj.ToDate) {
      if (!filterObj.FromDate || !filterObj.ToDate) {
        setFilterDateError('From date and To Date should be used as a range!');
        return false;
      }
      const fromStamp = moment(filterObj.FromDate).format('x');
      const toStamp = moment(filterObj.ToDate).format('x');
      if (fromStamp > toStamp) {
        setFilterDateError('From date should be a date before To Date!');
        return false;
      }
    }
    return true;
  };

  const filterSubmit = async (e) => {
    e.preventDefault();
    if (!validateFilterDates()) {
      return;
    }
    const filterValues = form.getFieldsValue();
    const tempFilterObj = { ...filterObj };
    tempFilterObj.clientCode = filterValues.clientCode;
    tempFilterObj.status = filterValues.invoiceStatus;
    applyFilter(tempFilterObj);
  };

  const handleDateChange = (dateString, propertyKey) => {
    const tempFilterObj = { ...filterObj };
    tempFilterObj[propertyKey] = dateString;
    setFilterObj(tempFilterObj);
  };

  const filterClear = () => {
    const obj = {
      FromDate: null,
      ToDate: null,
    };
    const tempFilterObj = { ...filterObj, ...obj };
    setFilterObj(tempFilterObj);
    applyFilter(tempFilterObj);
    form.resetFields();
    setFilterDateError(null);
  };

  return (
    <div className="filter-level-one-holder">
      <Form onSubmit={filterSubmit}>
        <div className="filter-form-content">
          <div className="filter-item relative-in">
            <span className="filter-form-error">{filterDateError}</span>
            <p className="picker-label">From</p>
            <div className="filter-item-control">
              <DatePicker
                form={form}
                value={filterObj ? filterObj.FromDate : null}
                format={Enums.Formats.DateFormat}
                onChange={(dateString) => { handleDateChange(dateString, 'FromDate'); }}
                fieldName="FromDate"
              />
            </div>
          </div>
          <div className="filter-item">
            <p className="picker-label">To</p>
            <div className="filter-item-control">
              <DatePicker
                value={filterObj ? filterObj.ToDate : null}
                format={Enums.Formats.DateFormat}
                onChange={(dateString) => { handleDateChange(dateString, 'ToDate'); }}
              />
            </div>
          </div>
          <div className="filter-button-holder">
            <Button htmlType="submit" type="primary">Filter</Button>
            <Button htmlType="button" type="dashed" onClick={filterClear}>Clear Filter</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

FilterForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  applyFilter: PropTypes.PropTypes.func.isRequired,
  setFilterObj: PropTypes.PropTypes.func.isRequired,
  filterObj: PropTypes.oneOfType([PropTypes.object]),
};

FilterForm.defaultProps = {
  filterObj: {},
};

const FilterFormWrapper = Form.create({ name: 'logs-filter-form' })(FilterForm);

const ActionLog = () => {
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [actionLogs, setActionLogs] = useState([]);
  const [filterObj, setFilterObj] = useState({});

  useEffect(() => {
    const getActionLogs = async () => {
      try {
        setTableDataLoading(true);
        const res = await getLogs(filterObj);
        setActionLogs(res.data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load action logs!');
      }
    };
    getActionLogs();
  }, []);

  const applyFilter = async (newFilterObj) => {
    try {
      const tempFilter = { ...filterObj };
      const updatedFilterObj = { ...tempFilter, ...newFilterObj };
      setTableDataLoading(true);
      const response = await getLogs(updatedFilterObj);
      setActionLogs(response.data);
      setTableDataLoading(false);
      setFilterObj(updatedFilterObj);
    } catch (error) {
      setTableDataLoading(false);
      Notifications.error('Someting went wrong while trying process your query.');
    }
  };

  const columns = [{
    title: 'User',
    dataIndex: 'userName',
    key: 'userName',
    width: 150,
  }, {
    title: 'Log Date',
    key: 'createdAt',
    render: (record) => {
      return moment(record.createdAt).format(Enums.Formats.DateFormat);
    },
    width: 150,
  }, {
    title: 'Logged Action',
    key: 'actionDescription',
    dataIndex: 'actionDescription',
  },
  ];

  return (
    <div>
      <div className="content-container">
        <FilterFormWrapper
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          applyFilter={applyFilter}
        />
        <Divider />
        <Table
          columns={columns}
          dataSource={actionLogs}
          loading={tableDataLoading}
        />
      </div>
    </div>
  );
};

export default ActionLog;
