import React, { useState, useEffect } from 'react';
import {
  Divider,
  Form,
  Button,
  DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Enums from '../../../constants/enum';
import {
  SelectBox,
} from '../../common/form-elements';
import Notifications from '../../common/notifications';
import { getClientsForFilter } from '../../../api/client';
import { getQuotationsForFilter } from '../../../api/quotation';
import { FilterCategory } from './category-filter';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';

const FilterForm = ({
  form,
  filterObj,
  applyFilter,
  setFilterObj,
  statuses,
  filterCategory,
}) => {
  const [clients, setClients] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [filterDateError, setFilterDateError] = useState(null);

  useEffect(() => {
    const gettingClients = async () => {
      try {
        const res = await getClientsForFilter();
        const settingClients = res.data.map((client) => {
          return {
            text: client.code,
            key: client.code,
          };
        });
        setClients(settingClients);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load clients!');
      }
    };
    const gettingQuotations = async () => {
      try {
        const res = await getQuotationsForFilter({ currency: getCurrencyFromLocalStorage() });
        const settingQuotations = res.data.map((quotation) => {
          return {
            text: quotation.code,
            key: quotation.code,
          };
        });
        setQuotations(settingQuotations);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load quotations!');
      }
    };
    gettingClients();
    gettingQuotations();
  }, []);

  const validateFilterDates = () => {
    setFilterDateError(null);
    if (filterObj.FromDate || filterObj.ToDate) {
      if (!filterObj.FromDate || !filterObj.ToDate) {
        setFilterDateError('From date and To Date should be used as a range!');
        return false;
      }
      const fromStamp = moment(filterObj.FromDate).format('x');
      const toStamp = moment(filterObj.ToDate).format('x');
      if (fromStamp > toStamp) {
        setFilterDateError('From date should be a date before To Date!');
        return false;
      }
    }
    return true;
  };

  const filterSubmit = async (e) => {
    e.preventDefault();
    if (!validateFilterDates()) {
      return;
    }
    const filterValues = form.getFieldsValue();
    const tempFilterObj = { ...filterObj };
    tempFilterObj.clientCode = filterValues.clientCode;
    tempFilterObj.status = filterValues.invoiceStatus;
    tempFilterObj.quotationCode = filterValues.quotationCode;
    applyFilter(tempFilterObj);
  };

  const handleDateChange = (dateString, propertyKey) => {
    const tempFilterObj = { ...filterObj };
    tempFilterObj[propertyKey] = dateString;
    setFilterObj(tempFilterObj);
  };

  const filterClear = () => {
    const obj = {
      clientCode: '',
      status: '',
      quotationCode: '',
      FromDate: null,
      ToDate: null,
    };
    const tempFilterObj = { ...filterObj, ...obj };
    setFilterObj(tempFilterObj);
    applyFilter(tempFilterObj);
    form.resetFields();
    setFilterDateError(null);
  };

  return (
    <div className="filter-level-one-holder">
      <Divider />
      <Form onSubmit={filterSubmit}>
        <div className="filter-form-content">
          <div className="filter-item">
            <p>Client</p>
            <div className="filter-item-control">
              <SelectBox
                form={form}
                fieldName="clientCode"
                options={clients}
              />
            </div>
          </div>
          <div className="filter-item">
            <p>Quotation</p>
            <div className="filter-item-control">
              <SelectBox
                form={form}
                fieldName="quotationCode"
                options={quotations}
              />
            </div>
          </div>
          <div className="filter-item relative-in">
            <span className="filter-form-error">{filterDateError}</span>
            <p className="picker-label">From</p>
            <div className="filter-item-control">
              <DatePicker
                form={form}
                value={filterObj ? filterObj.FromDate : null}
                format={Enums.Formats.DateFormat}
                onChange={(dateString) => { handleDateChange(dateString, 'FromDate'); }}
                fieldName="FromDate"
                disabled={filterCategory === FilterCategory.ThisMonth
                  || filterCategory === FilterCategory.NextMonth}
              />
            </div>
          </div>
          <div className="filter-item">
            <p className="picker-label">To</p>
            <div className="filter-item-control">
              <DatePicker
                value={filterObj ? filterObj.ToDate : null}
                format={Enums.Formats.DateFormat}
                onChange={(dateString) => { handleDateChange(dateString, 'ToDate'); }}
                disabled={filterCategory === FilterCategory.ThisMonth
                  || filterCategory === FilterCategory.NextMonth}
              />
            </div>
          </div>
          <div className="filter-item">
            <p>Status</p>
            <div className="filter-item-control">
              <SelectBox
                form={form}
                fieldName="invoiceStatus"
                options={statuses}
                textValueSplit
                disabled={filterCategory === FilterCategory.Due}
              />
            </div>
          </div>
          <div className="filter-button-holder">
            <Button htmlType="submit" type="primary">Filter</Button>
            <Button htmlType="button" type="dashed" onClick={filterClear}>Clear Filter</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

FilterForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  applyFilter: PropTypes.PropTypes.func.isRequired,
  setFilterObj: PropTypes.PropTypes.func.isRequired,
  filterObj: PropTypes.oneOfType([PropTypes.object]),
  statuses: PropTypes.oneOfType([PropTypes.array]).isRequired,
  filterCategory: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

FilterForm.defaultProps = {
  filterObj: {},
};

const FilterFormWrapper = Form.create({ name: 'invoice-filter-form' })(FilterForm);

export default FilterFormWrapper;
