import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import AuthContainer from '../../containers/auth';
import RouterRegistry from '../../router/registry';
import { InputElem } from '../common/form-elements';
import 'antd/dist/antd.css';
import './index.css';
import fidenzSignia from '../../assets/images/fidenz_logo.svg';

const Login = ({ form, location }) => {
  const authService = AuthContainer.useContainer();
  const history = useHistory();
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (authService.isAuthenticated()) {
      if (location.state) {
        history.push(location.state.from.pathname);
        return;
      }
      history.push(RouterRegistry.dashboard.path);
    }
  }, [authService.authenticated, authService, location, history]);

  const authErrorHandler = (error) => {
    if (error) {
      const { message } = error;
      setErrorMessage(message);
    }
    setLoginLoading(false);
  };

  const authSuccessHandler = () => {
    history.push(RouterRegistry.dashboard.path);
  };

  const authLogin = async ({ username, password }) => {
    await authService.auth0Login(username, password, authErrorHandler, authSuccessHandler);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoginLoading(true);
        authLogin(values);
      }
    });
  };

  const handleForgetPassword = () => {
    history.push(RouterRegistry.forgotPassword.path);
  };

  const handleKeyPress = () => {

  };

  return (
    <div className="login-form-container">
      <Form onSubmit={handleSubmit} className="login-form">
        <div className="logo-holder">
          <img
            className="logo-svg"
            src={fidenzSignia}
            alt="Fidenz Technologies"
          />
          <p className="logo-text">Billar</p>
        </div>
        <InputElem
          form={form}
          fieldName="username"
          rules={[
            { required: true, message: 'Please enter a Username' },
            { type: 'email', message: 'Enter a valid E-mail' },
          ]}
          placeholder="Username"
          type="text"
          icon="user"
        />
        <InputElem
          form={form}
          fieldName="password"
          rules={[
            { required: true, message: 'Please enter a Password' },
          ]}
          placeholder="Password"
          type="password"
          icon="lock"
        />
        <div className="test">
          <p className="login-error">{errorMessage}</p>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loginLoading}>
            Log in
          </Button>
        </Form.Item>
        <span onClick={handleForgetPassword} role="button" tabIndex="0" onKeyPress={handleKeyPress} className="login-form-link"> Forgot Password? </span>
      </Form>
    </div>
  );
};

Login.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const LoginForm = Form.create({ name: 'app_login' })(Login);

export default LoginForm;
