import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';

const PageReload = (preAction) => {
  Modal.info({
    title: 'Page Reload',
    content: (
      <div>
        <p>
          The page need to reload to affect this change properly.
          Please press &apos;OK&apos; button and wait until the automatic page reload.
        </p>
      </div>
    ),
    onOk() {
      preAction();
      window.location.reload();
    },
  });
};

PageReload.propTypes = {
  record: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default PageReload;
