import React from 'react';
import {
  Divider,
  Input,
  DatePicker,
  InputNumber,
} from 'antd';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import { InputList } from '../../common/form-elements';
import Enums from '../../../constants/enum';
import { thousandSeparator, getSum } from '../../../util/data/converter';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import './index.css';

const PaymentPlanSet = ({
  paymentPlans,
  SetPaymentPlans,
  paymentError,
  setPaymentError,
}) => {
  const addPaymentPlan = (key) => {
    const items = [...paymentPlans];
    const newItem = {
      key,
      description: '',
      date: moment().format(Enums.Formats.DateFormat),
      amount: '',
    };
    items.push(newItem);
    SetPaymentPlans(items);
  };

  const handleChange = (event, identifier) => {
    const { name, value } = event.target;
    const pPlans = [...paymentPlans];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], [name]: value };
    SetPaymentPlans(pPlans);
  };

  const handleNumberChange = (value, name, identifier) => {
    const pPlans = [...paymentPlans];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], [name]: value };
    SetPaymentPlans(pPlans);
  };

  const handleDateChange = (dateString, identifier) => {
    const pPlans = [...paymentPlans];
    setPaymentError(null);
    // pPlans.forEach((pItem) => {
    //   const dateStampOfPrev = moment(pItem.date).format('x');
    //   const dateStampOfCurrent = moment(dateString).format('x');
    //   if (dateStampOfCurrent < dateStampOfPrev) {
    //     setPaymentError('Each invoice date should be ahead of the previous invoice date.');
    //   }
    // });
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans[indexOf] = { ...pPlans[indexOf], date: dateString };
    SetPaymentPlans(pPlans);
  };

  const removeItem = (identifier) => {
    const pPlans = [...paymentPlans];
    const indexOf = findIndex(pPlans, (i) => i.key === identifier);
    pPlans.splice(indexOf, 1);
    SetPaymentPlans(pPlans);
  };

  const ShowTotal = () => {
    const totalValue = getSum(paymentPlans.map((item) => item.amount));

    return (
      <div className="repeatable-total">
        {thousandSeparator(totalValue)}
      </div>
    );
  };

  const ErrorStatus = () => {
    if (paymentError) {
      return <p className="repeatable-form-error">{paymentError}</p>;
    }
    return null;
  };

  return (
    <div className="repeatable-form-container">
      <Divider />
      <p className="repeatable-form-title">Payment Plan</p>
      <InputList
        items={
          paymentPlans.map((item) => ({
            key: item.key,
            body: (
              <>
                <Input
                  type="text"
                  name="description"
                  value={item.description}
                  placeholder="Description"
                  onChange={(e) => { handleChange(e, item.key); }}
                  className="pp-description"
                />
                <DatePicker
                  value={moment(item.date)}
                  onChange={(dateString) => { handleDateChange(dateString, item.key); }}
                  format={Enums.Formats.DateFormat}
                  className="pp-datepicker"
                />
                <InputNumber
                  precision={Enums.Formats.AmountPrecision}
                  name="amount"
                  value={item.amount}
                  placeholder={`Amount (${getCurrencyFromLocalStorage()})`}
                  onChange={(value) => { handleNumberChange(value, 'amount', item.key); }}
                  className="pp-amount"
                />
              </>
            ),
          }))
        }
        addItem={addPaymentPlan}
        removeItem={removeItem}
      />
      <ErrorStatus />
      <ShowTotal />
    </div>
  );
};

PaymentPlanSet.propTypes = {
  paymentPlans: PropTypes.func.isRequired,
  SetPaymentPlans: PropTypes.func.isRequired,
  paymentError: PropTypes.string.isRequired,
  setPaymentError: PropTypes.func.isRequired,
};

export default PaymentPlanSet;
