import axios from './api-controller';
import { castToURLQueryParamsString } from './util';

const subPath = 'role';

const getRoles = async (queryParams) => {
  const queryString = castToURLQueryParamsString(queryParams);
  const response = await axios.get(`${subPath}${queryString}`);
  return response;
};

export default getRoles;
