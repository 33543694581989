const Formats = Object.freeze({
  DateFormat: 'YYYY/MM/DD',
  DateLongFormat: 'DD-MMMM-YYYY',
  DateMidFormat: 'DD-MMM-YYYY',
  EnglishDateFormat: 'MM/DD/YYYY',
  AmountPrecision: 2,
});

const InvoiceStatus = Object.freeze({
  PENDING: 'Pending',
  CREATED: 'Created',
  HOLD: 'Hold',
  PARTIALLYINVOICED: 'PartiallyInvoiced',
  COMPLETED: 'Completed',
  IGNORE: 'Ignore',
  DUE: 'Due',
});

const QuotationStatus = Object.freeze({
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
  FULLYINVOICED: 'FullyInvoiced',
});

const ServiceBillStatus = Object.freeze({
  DRAFT: 'Draft',
  UNSENT: 'UnSent',
  SENT: 'Sent',
  PAID: 'Paid',
  OVERDUE: 'OverDue',
});

const Sections = Object.freeze({
  QUOTATIONS: 'Quotations',
  INVOICES: 'Invoices',
  SERVICEBILLS: 'ServiceBills',
});

const Access = Object.freeze({
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
});

const Roles = Object.freeze({
  ADMIN: 'Admin',
  MEMBER: 'Member',
});

export default {
  Formats,
  InvoiceStatus,
  QuotationStatus,
  ServiceBillStatus,
  Sections,
  Access,
  Roles,
};
