import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputElem,
} from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import {
  getProductCategory,
  createProductCategory,
  updateProductCategory,
} from '../../../api/product-category';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import 'antd/dist/antd.css';
import './index.css';

const ProductCategoryForm = ({ form, match }) => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingProductCategory, setExistingProductCategory] = useState(null);

  useEffect(() => {
    const getSelectedProductCategory = async () => {
      if (match.params.id) {
        const res = await getProductCategory(match.params.id);
        setExistingProductCategory(res.data);
      }
    };

    getSelectedProductCategory();
    // eslint-disable-next-line
  }, []);

  const saveProductCategory = async (values) => {
    try {
      setSubmitting(true);
      const productCategoryObj = {
        name: values.name,
        description: values.description,
      };
      if (existingProductCategory) {
        const productCategoryObjWithId = { id: existingProductCategory.id, ...productCategoryObj };
        await updateProductCategory(productCategoryObjWithId);
        Notifcations.success('Product category successfully updated.');
      } else {
        await createProductCategory(productCategoryObj);
        Notifcations.success('Product category successfully created.');
      }
      setSubmitting(false);
      goHere(history, RouteRegistry.productCategory.path);
    } catch (error) {
      setSubmitting(false);
      Notifcations.error('Something went wrong while managing product category.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveProductCategory(values);
      }
    });
  };

  const formSubmitButtonText = () => {
    if (existingProductCategory) {
      return 'Update';
    }
    return 'Create';
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form onSubmit={handleSubmit} className="product-category-form">
          <InputElem
            form={form}
            fieldName="name"
            rules={[
              { required: true, message: 'Please enter product category name' },
            ]}
            placeholder="Product Category*"
            type="text"
            initialValue={existingProductCategory ? existingProductCategory.name : null}
          />
          <InputElem
            form={form}
            fieldName="description"
            placeholder="Category Description"
            type="text"
            initialValue={existingProductCategory ? existingProductCategory.description : ''}
            area
          />
          <Form.Item className="no-bottom-gutter">
            <Button type="primary" htmlType="submit" className="product-category-form-button button-right" size="large" loading={isSubmitting}>
              {formSubmitButtonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

ProductCategoryForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const ProductCategoryFormWrapper = Form.create({ name: 'product-category-form' })(ProductCategoryForm);

export default ProductCategoryFormWrapper;
