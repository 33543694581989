import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Divider,
  Modal,
  Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import { deleteProductCategory, getProductCategories } from '../../../api/product-category';
import './grid.css';

const ProductCategoryGrid = () => {
  const history = useHistory();
  const [productCategories, setProductCategories] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  useEffect(() => {
    const gettingProductCategories = async () => {
      try {
        setTableDataLoading(true);
        const res = await getProductCategories({});
        setProductCategories(res.data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load product categories!');
      }
    };

    gettingProductCategories();
    // eslint-disable-next-line
  }, []);

  const createProductCategory = () => {
    goHere(history, RouteRegistry.productCategoryCreate.path);
  };

  const editProductCategory = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.productCategory.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.productCategoryUpdate.path,
      },
    });
  };

  const deleteSelectedProductCategory = async (id) => {
    try {
      setTableDataLoading(true);
      await deleteProductCategory(id);
      const res = await getProductCategories({});
      setProductCategories(res.data);
      Notifications.success('Product Category successfully deleted.');
      setTableDataLoading(false);
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete Product Category.');
      setTableDataLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Delete Product Category?',
      content: 'Are you sure you want to delete this Product Category?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSelectedProductCategory(id);
      },
    });
  };

  const GridActions = ({ record }) => {
    return (
      <div className="grid-action-row">
        {/*
        Product category will be link to the invoice or invoice item in future.
        Once it done bellow method must be properly implimnet,
        to display the deletable product categories to user
        -- Rid Rupasinghe : 19-12-2020 --
        */}
        {record.invoiceCount === 0
          ? <Tag color="#f5222d">Used in Invoces</Tag>
          : (
            <div className="grid-action-row">
              <Icon type="form" className="grid-action-icon" onClick={() => { editProductCategory(record.id); }} />
              <Icon type="delete" className="grid-action-icon" onClick={() => { confirmDelete(record.id); }} />
            </div>
          )}
      </div>
    );
  };

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => {
        return (record.description.length > 80
          ? `${record.description.substr(0, 80)}...`
          : record.description
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 300,
      render: (record) => {
        return (<GridActions record={record} />);
      },
    },
  ];

  return (
    <div className="content-container">
      <div className="create-product-category-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={createProductCategory}>
          Create New Product Category
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={productCategories}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default ProductCategoryGrid;
